import React from "react";
import styled from "styled-components";
import moment from "moment";

interface ScheduleBoxProps {
  isSelected: boolean;
  item: any;
  onClick: () => void;
}

const ScheduleBox: React.FC<ScheduleBoxProps> = ({
  isSelected,
  onClick,
  item,
}) => {
  const initialDate = new Date(item?.requestedDatetime);

  const currentTime = new Date().getTime();
  const scheduleTime = initialDate.getTime();
  const timeDifferenceInHours = (scheduleTime - currentTime) / (1000 * 60 * 60);

  const isWithinFourHours =
    timeDifferenceInHours <= 4 && timeDifferenceInHours >= 0;

  return (
    <Container isselected={isSelected} onClick={onClick}>
      <Header>
        <LeftText>{item?.name}</LeftText>
        {item?.isViewed && (
        <RightText>Viewed</RightText>
        )}
      </Header>
      <NotaryOwner>
        <ScheduleText isWithinFourHours={isWithinFourHours}>
          Scheduled:
        </ScheduleText>{" "}
        {moment(initialDate).format("M/D/YY h:mma")}
      </NotaryOwner>
      <InfoRow>
        <InfoBlock>
          <Label>Appt. ID</Label>
          <Value>{item?.appointmentId}</Value>
        </InfoBlock>
        <InfoBlock>
          <Label>Notary</Label>
          <Value>{item?.officialName}</Value>
        </InfoBlock>
      </InfoRow>
    </Container>
  );
};

export default ScheduleBox;


export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  margin-top: 8px;
  padding: 14px;
  border-radius: 8px;
`;


export const Container = styled.div<{
  isselected: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.isselected ? "1px solid blue" : "none")};
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  cursor: pointer;
  background: ${(props) => (props.isselected ? props.theme.colors.light : "white")};
  border-radius: 10px;
  &:last-child {
    border-bottom: none;
  }

  /* Adding box-shadow when selected */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* Hover effect */
  &:hover {
    background: ${(props) => (props.isselected ? "none" : "#EBEBEB")};

    ${InfoRow} {
      background-color: white;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
`;

export const LeftText = styled.div`
  color: #333344;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  max-width: 175px; /* Set the max width */
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent text wrapping */
  scrollbar-width: thin; /* For Firefox */
  
 scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

`;

export const RightText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-right: 5px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.span`
  color: #555;
  font-size: 14px;
`;

export const NotaryOwner = styled.span`
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ScheduleText = styled.span<{ isWithinFourHours: boolean }>`
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  color: ${(props) => (props.isWithinFourHours ? "#EA4125" : "#EA8223")};
`;

export const Value = styled.span`
  font-size: 16px;
`;
