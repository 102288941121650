import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 16px;
  padding: 15px 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: ${(props) => props.theme.colors.dark};
  }
`;
