import React, {
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useMemo,
} from "react";
import styled from "styled-components";
import { SingleValue, ActionMeta } from "react-select";
import SessionActionDropdown, { options } from "./SessionActionDropdown";
import SessionActionCompletedDropdown, { completedOptions } from "./SessionActionDropdownCompleted";
import Dropdown from "./Dropdown";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as RequestIcon } from "../../assets/request.svg";
import { ReactComponent as NewDocumentIcon } from "../../assets/create_session.svg";
import { ReactComponent as EditIconSvg } from "../../assets/edit-icon-grey.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash-grey.svg";
import { ReactComponent as BookIcon } from "../../assets/grey-book-icon.svg";
import { MdOutlineEdit } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { FaSignal } from 'react-icons/fa';
import { RiDeleteBinLine } from "react-icons/ri";
import Counter from "./Counter";
import { COMPLETED_SESSION_STATUSES, COMPLETED_STATUS, STAGES_BEFORE_CAMERA_CHECK, TIME_ZONES, createOfficialLink, createSharedDocsAppLink, createUserLinkForCopyDownloadPageLink, createUserLinkForCopySessionInvite, displayByStatus, isUserOnline, } from "../../utils/sessionHelper";
import FloatingLabelTextarea from "./StyledTextarea";
import {
  AppDispatch,
  RootState,
  checkSessionStatus,
  deleteDocument,
  getDocument,
  getOfficials,
  getRecords,
  sendNotification,
  updateSessionDetail,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  getCredentialAnalysisStatus,
  getDevice,
  getQuizStatus,
} from "../../utils/sessionHelper";
import {
  CANCELLED_STATUS,
  SESSION_STAGES,
  START_STATUS,
} from "../../constants/sessions";
import { ToastContentProps, toast } from "react-toastify";
import {
  notaryLink,
  removeBase64Header,
  resizeSignature,
} from "../../utils/helper";
import { checkFeatureAvailability } from "../../utils/permissions";
import RequestDocumentPopup from "./RequestDocumentPopup";
import UploadLinkPopup from "./UploadLinkPopup";
import { handleDownloadDoc } from "../../utils/downloadDoc";
import StepTwoPopup from "./AddDocPopop";
import SendFeedbackPopup from "./SendFedbackPopup";
import AcknowledgmentModal, { } from "./ViewRecordModal";

interface OptionType {
  value: string;
  label: string;
}

const sessionTypes: OptionType[] = [
  {
    value: "Signer is a US citizen or resident",
    label: "Signer is a US citizen or resident",
  },
  { value: "Forms", label: "Forms" },
];

const notaryOwners: OptionType[] = [
  { value: "Greg Peters", label: "Greg Peters" },
  { value: "Alice Thompson", label: "Alice Thompson" },
  { value: "Emily Williams", label: "Emily Williams" },
  { value: "Daniel Brown", label: "Daniel Brown" },
];

const labelMapping: { [key: string]: string } = {
  signerName: "Signer name",
  email: "Signer email",
  userPhone: "Signer phone",
  userTimezone: "Signer timezone",
  copyRecipient1: "Copy recipient 1",
  copyRecipient2: "Copy recipient 2",
  payerEmail: "Payer email",
  sessionType: "Session type",
};

const SessionSummary: React.FC<{
  session: any; activeScheduleTab: "scheduled" | "completed"; handleSessionSelect: (session: any) => void;
}> = ({ session, activeScheduleTab, handleSessionSelect }) => {
  const [showCanvas, setShowCanvas] = useState(false);
  const [pdfPreviewCanvasId, setPdfPreviewCanvasId] = useState(
    `preview-document-canvas-${new Date().getTime()}`
  );
  const [uploadedSignaturesDataImages, setUploadedSignaturesDataImages] = useState([]);
  const [insertedSignatures, setInsertedSignatures] = useState([]);
  const [insertedTextNodes, setInsertedTextNodes] = useState([]);

  const [activeTab, setActiveTab] = useState("Session Documents");
  const dispatch = useDispatch<AppDispatch>();
  const [isRequestDocPopupVisible, setIsRequestDocPopupVisible] =
    useState(false);
  const [isSessionFeedbackPopupVisible, setIsSessionFeedbackPopupVisible] =
    useState(false);
  const [selectedSessionType, setSelectedSessionType] = useState<OptionType>(
    sessionTypes.find((type) => type.value === session?.appointmentType) ||
    sessionTypes[0]
  );
  const [isSignerWebRTCOk, setIsSignerWebRTCOk] = useState(false);
  const [isUploadLinkPopupOpen, setIsUploadLinkPopupOpen] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [editIframeUrl, setEditIframeUrl] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<OptionType>(options[0]);
  const [selectedCompletedAction, setSelectedCompletedAction] = useState<OptionType>(completedOptions[0]);
  const [selectedNotaryOwner, setSelectedNotaryOwner] = useState<OptionType>(
    notaryOwners[0]
  );
  const [textareaValue, setTextareaValue] = useState(
    session?.officialPrivateNotes || ""
  );
  const [selectedSessionStage, setSelectedSessionStage] = useState<OptionType>({
    value: session?.stage,
    label: session?.stage,
  });
  const [cancelSessionNote, setCancelSessionNote] = useState<string>("");
  const [selectedDocumentSeal, setSelectedDocumentSeal] = useState<number>(
    session?.documentsCount || 0
  );
  const [updatedNotaryOwner, setUpdatedNotaryOwner] =
    useState<OptionType | null>(null);
  const [isNotaryOwnerEditable, setIsNotaryOwnerEditable] = useState(false);
  const [isDocumentSealEditable, setIsDocumentSealEditable] = useState(false);

  const [documentId, setDocumentId] = useState("");
  const [documents, setDocuments] = useState<any[]>([]);
  const [isCancelSessionPopupVisible, setIsCancelSessionPopupVisible] =
    useState(false);
  const [isCancelSessionInputFocused, setIsCancelSessionInputFocused] =
    useState(false);
  const [isRestartSessionPopupVisible, setIsRestartSessionPopupVisible] =
    useState(false);
  const [isDeleteDocPopupVisible, setIsDeleteDocPopupVisible] =
    useState(false);
  const [
    isDisableAudioVideoSessionPopupVisible,
    setIsDisableAudioVideoSessionPopupVisible,
  ] = useState(false);
  const [isNotesEditable, setIsNoteEditable] = useState(false);
  const [sessionRecords, setSessionRecords] = useState([])
  const [isSessionStageEditable, setIsSessionStageEditable] = useState(false);
  const [isCancelSessionLoading, setIsCancelSessionLoading] = useState(false);
  const [isRestartSessionLoading, setIsRestartSessionLoading] = useState(false);
  const [isDeleteDocLoading, setIsDeleteDocLoading] = useState(false);
  const [isEnableDisableSessionLoading, setIsEnableDisableSessionLoading] =
    useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [updatedName, setUpdatedName] = useState(session?.user?.fullName || "");
  const [uploadedImage, setUploadedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [isAddDocPopupOpen, setIsAddDocPopupOpen] = useState(false);

  const {
    updatingSession: { loading: updateLoading },
  } = useSelector((state: RootState) => state.session);

  const [isDateEditable, setIsDateEditable] = useState(false);
  // const [selectedDate, setSelectedDate] = useState<Date>(
  //   session?.date ? new Date(session.date) : new Date("July 5, 2024")
  // );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [isViewRecordModalOpen, setIsViewRecordModalOpen] = useState(false);
  const [isAssignmentAvailable, setIsAssignmentAvailable] = useState(false);
  const initialDate = session?.scheduledAt?._seconds
    ? moment(session.scheduledAt._seconds * 1000)
      // .add(1, "hours")
      .toDate()
    : null;
  const completedDate = session?.finishedAt?._seconds
    ? moment(session.finishedAt._seconds * 1000)
      // .add(1, "hours")
      .toDate()
    : null;
  const [officials, setOfficials] = useState<any[]>([]);
  const [sessionDate, setSessionDate] = useState<Date | null>(initialDate);
  const [completedSessionDate, setCompletedSessionDate] = useState<Date | null>(completedDate);
  const [tempDate, setTempDate] = useState<Date | null>(initialDate);
  const [isSessionDetailsEditable, setIsSessionDetailsEditable] =
    useState(false);
  const [updatedStage, setUpdatedStage] = useState<OptionType | null>(null);
  const [deleteDocId, setDeleteDocId] = useState(null);
  const [updatedDocumentSeal, setUpdatedDocumentSeal] = useState<number | null>(
    null
  );
  const [signerDetail, setSignerDetail] = useState({
    signerName: session?.user?.fullName || "",
    email: session?.user?.email || "",
    userPhone: session?.userPhone || "",
    userTimezone: session?.userTimezone || "",
    copyRecipient1: session?.emailCopies[0] || "",
    copyRecipient2: session?.emailCopies[1] || "",
    payerEmail: session?.payerEmail || "",
    sessionType: session?.appointmentType || "",
  });
  const [isSignerDetailEditable, setIsSignerDetailEditable] = useState(false);
  const [isNotaryAdmin, setIsNotaryAdmin] = useState(false);

  const isOfficialOnline = useMemo(() => isUserOnline(session), [session]);
  const isSignerOnline = useMemo(() => isUserOnline(session, 'signerLastOnlineAt'), [session]);


  const datePickerRef = useRef<HTMLDivElement>(null);
  const personaClientRef = useRef<any>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);


  useEffect(() => {
    if (session?.signerConnectivity && session?.signerConnectivity?.stats) {
      setIsSignerWebRTCOk(true);
    }
    if (
      !session?.userIO ||
      !session?.userIO?.audioDevices ||
      !session?.userIO?.videoDevices ||
      !(session?.userIO?.audioDevices?.length && session?.userIO?.videoDevices?.length)
    ) {

    } else {
      setIsSignerWebRTCOk(true);

    }
  }, [session]);


  const handleSignerDetailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSignerDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "userPhone") {
      validatePhoneNumber(value);
    }
  };

  const handleSignerDetailEditClick = () => {
    setIsSignerDetailEditable(true);
  };

  const handleSignerDetailSave = async () => {
    let updatingSignerDetail = {};
    // if (!validatePhoneNumber(signerDetail.userPhone)) {
    //   console.log("phone error 1")
    //   toast.error("Phone number must be 10 digits.", { autoClose: 5000 });
    //   return;
    // }
    if (signerDetail.copyRecipient1 && signerDetail.copyRecipient2) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient1, signerDetail.copyRecipient2],
      };
    } else if (signerDetail.copyRecipient1) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient1],
      };
    } else if (signerDetail.copyRecipient2) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient2],
      };
    }
    if (signerDetail.payerEmail) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        payerEmail: signerDetail.payerEmail,
      };
    }
    if (signerDetail.signerName) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        signerName: signerDetail.signerName,
      };
    }
    // if (signerDetail.signers) {
    //   updatingSignerDetail = {
    //     ...updatingSignerDetail,
    //     multipleSigner: signerDetail.signers === "Multiple Signers",
    //   };
    // }
    if (signerDetail.userTimezone) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        userTimezone: signerDetail.userTimezone,
      };
    }
    if (signerDetail.userPhone) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        userPhone: signerDetail.userPhone,
      };
    }
    if (signerDetail?.sessionType) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        appointmentType: signerDetail?.sessionType,
      };
    }
    try {

      await handleUpdateDetails(updatingSignerDetail, "signerDetail");
      handleSessionSelect({
        ...session,
        ...updatingSignerDetail
      })
      setIsSignerDetailEditable(false);
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const handleRequestDoc = async () => {
    setIsUploadLinkPopupOpen(false);
    setIsRequestDocPopupVisible(true);
  };

  const handleSessionFeedback = async (officialFeedback: string) => {
    try {

      await handleUpdateDetails(
        { officialFeedback },
        "sessionFeedback"
      );
      handleSessionSelect({
        ...session,
        officialFeedback
      })
      setIsSessionFeedbackPopupVisible(false);
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const openSessionFeedback = async () => {
    setIsSessionFeedbackPopupVisible(true);
  };

  const handlePreviewDocument = (documentUrl: string) => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    } else {
      toast.error("Document URL is invalid or missing.", {
        autoClose: 5000,
      });
    }
  };

  const handleSessionTypeChange = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (newValue) {
      setSelectedSessionType(newValue);
      setSignerDetail((prevState) => ({
        ...prevState,
        sessionType: newValue.value || "",
      }));
    }
  };

  const handleChangeSessionStage = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      if (newValue && newValue.value !== session?.stage) {
        setSelectedSessionStage(newValue);
        setUpdatedStage(newValue);
        setIsSessionStageEditable(true);
      } else {
        setIsSessionStageEditable(false);
      }
    },
    [session]
  );

  const handleDocumentSealChange = (newValue: number) => {
    if (newValue !== session?.documentsCount) {
      setSelectedDocumentSeal(newValue);
      setUpdatedDocumentSeal(newValue);
      setIsDocumentSealEditable(true);
    } else {
      setIsDocumentSealEditable(false);
    }
  };

  const handleCopyAppointmentId = () => {
    if (session?.appointmentId) {
      navigator.clipboard
        .writeText(session.appointmentId)
        .then(() => {
          toast.info("Appointment ID copied to clipboard!", {
            autoClose: 3000,
          });
        })
        .catch(() => {
          toast.error("Failed to copy Appointment ID.", {
            autoClose: 3000,
          });
        });
    }
  };

  const handleSignerDetailCancel = () => {
    setIsSignerDetailEditable(false);
    setSignerDetail({
      signerName: session?.user?.fullName || "",
      email: session?.user?.email || "",
      userPhone: session?.userPhone || "",
      userTimezone: session?.userTimezone || "",
      copyRecipient1: session?.emailCopies[0] || "",
      copyRecipient2: session?.emailCopies[1] || "",
      payerEmail: session?.payerEmail || "",
      sessionType: session?.appointmentType || "",
      // signers: session?.multipleSigner ? "Multiple Signers" : "Single Signer",
    });
  };

  const openCancelSessionPopup = () => setIsCancelSessionPopupVisible(true);
  const closeCancelSessionPopup = () => setIsCancelSessionPopupVisible(false);

  const openRestartSessionPopup = () => setIsRestartSessionPopupVisible(true);
  const closeRestartSessionPopup = () => setIsRestartSessionPopupVisible(false);
  const openDeleteDocPopup = () => setIsDeleteDocPopupVisible(true);
  const closeDeleteDocPopup = () => setIsDeleteDocPopupVisible(false);

  const openDisableAudioVideoSessionPopup = () =>
    setIsDisableAudioVideoSessionPopupVisible(true);
  const closeDisableAudioVideoSessionPopup = () =>
    setIsDisableAudioVideoSessionPopupVisible(false);

  const handleChangeAction = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      if (newValue !== null) {
        setSelectedAction(newValue);
      }
    },
    []
  );

  const handleChangeCompletedAction = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      if (newValue !== null) {
        setSelectedCompletedAction(newValue);
      }
    },
    []
  );

  const handleChangeNotaryOwner = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      const selectedOfficial = officials.find(
        (official) => official.name === newValue?.value
      );

      if (
        selectedOfficial &&
        selectedOfficial.name !== session?.official?.name
      ) {
        setSelectedNotaryOwner({
          value: selectedOfficial.name,
          label: selectedOfficial.name,
        });
        if (selectedOfficial?.commissionExpirationDate) {
          const date = new Date(
            selectedOfficial?.commissionExpirationDate?._seconds * 1000
          );
          const formattedDate = date.toString();
          selectedOfficial["commissionExpirationDate"] = formattedDate;
        }
        setUpdatedNotaryOwner(selectedOfficial);
        setIsNotaryOwnerEditable(true);
      } else {
        setIsNotaryOwnerEditable(false);
      }
    },
    [officials, session]
  );

  const handleNotaryOwnerSave = async () => {
    const updateData: any = {};
    if (updatedNotaryOwner) {
      updateData.official = { ...updatedNotaryOwner };
    }
    if (updatedDocumentSeal !== null) {
      updateData.documentsCount = updatedDocumentSeal;
    }
    try {
      await handleUpdateDetails(updateData, "notaryOwner");
      handleSessionSelect({
        ...session,
        updateData
      })
      setIsNotaryOwnerEditable(false);
      setUpdatedNotaryOwner(null);
      setUpdatedDocumentSeal(null);
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const handleNotaryOwnerCancel = () => {
    setIsNotaryOwnerEditable(false);
    setIsDocumentSealEditable(false);
    setUpdatedNotaryOwner(null);
    setSelectedNotaryOwner({
      value: session?.official?.name,
      label: session?.official?.name,
    });
  };

  const openAddDocPopup = () => {
    setIsAddDocPopupOpen(true);
  };

  const closeAddDocPopup = () => {
    setIsAddDocPopupOpen(false);
  };

  const fetchDocument = async ({ id }: any) => {
    try {
      const getDocumentResponse = await dispatch(
        getDocument({ sessionId: id })
      );

      if (getDocumentResponse.meta.requestStatus == API_STATUS.fulfilled) {
        setDocuments([...getDocumentResponse?.payload]);
        const documentIdTemp = getDocumentResponse?.payload[0]?.document_id;
        setDocumentId(documentIdTemp);
        return documentIdTemp;
      }
      if (getDocumentResponse.meta.requestStatus == "rejected") {
        toast.error("Something went wrong", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("error in get session api", error);
    }
  };

  const handleDateEditClick = () => {
    if (session) setIsDateEditable(true);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setTempDate(date);
      setSelectedDate(date);
    }
  };

  const handleSaveDate = async () => {
    try {
      await handleUpdateDetails(
        { scheduledAt: moment(tempDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ") },
        "scheduling"
      );
      const date = new Date(moment(tempDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
      const scheduledAt = {
        _seconds: Math.floor(date.getTime() / 1000),
        _nanoseconds: (date.getMilliseconds() * 1e6)
      };
      handleSessionSelect({
        ...session,
        scheduledAt
      })
      setSessionDate(moment(tempDate).toDate());
      setSelectedDate(moment(tempDate).toDate());
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const handleCancelDateEdit = () => {
    setTempDate(sessionDate);
    setIsDateEditable(false);
  };

  const validatePhoneNumber = (phone: string) => {
    console.log("phone", phone)
    const phoneRegex = /^[0-9]{10}$/;
    // if (phone) {
    //   if (!phoneRegex.test(phone)) {
    //     console.log("phone error")
    //     setPhoneError("Phone number must be 10 digits.");
    //     return false;
    //   }
    // }
    setPhoneError(null);
    return true;
  };

  useEffect(() => {
    adjustTextareaHeight();

  }, [textareaValue])

  useEffect(() => {
    (async () => {
      if (session?.id) {
        setTextareaValue(session?.officialPrivateNotes || "");
        // adjustTextareaHeight();
        setDeleteDocId(null);
        setSessionRecords([]);
        setIsDateEditable(false);
        setIsDocumentSealEditable(false);
        setIsNameEditable(false);
        setIsNotaryOwnerEditable(false);
        setIsNoteEditable(false);
        setIsSessionStageEditable(false);
        setIsSessionDetailsEditable(false);
        setIsSignerDetailEditable(false);
        const documentIdTemp = await fetchDocument(session);
        const str = createOfficialLink(notaryLink, session?.security);
        const iframeUrlTemp =
          str.substring(0, str.indexOf("sid=")) +
          `#/document/${documentIdTemp}`;
        setIframeUrl(iframeUrlTemp);
        const isAvailable: any = await checkFeatureAvailability();
        setIsAssignmentAvailable(isAvailable);
        const getOfficialsResponse = await dispatch(getOfficials({}));
        if (getOfficialsResponse.meta.requestStatus === "fulfilled") {
          setOfficials([...getOfficialsResponse.payload]);
        }
        if (getOfficialsResponse.meta.requestStatus == "rejected") {
          toast.error("Something went wrong", {
            autoClose: 5000,
          });
        }
      }
    })();
  }, [session?.id]);

  useEffect(() => {
    // setIsDateEditable(false);
    if (session) {

      const completedDate = session?.finishedAt?._seconds
        ? moment(session.finishedAt._seconds * 1000)
          // .add(1, "hours")
          .toDate()
        : null;
      setCompletedSessionDate(completedDate);

      setUpdatedName(session?.user?.fullName || "");
      if (!isSignerDetailEditable)
        setSignerDetail({
          ...signerDetail,
          payerEmail: session?.payerEmail || "",
          email: session?.user?.email || "",
          signerName: session?.user?.fullName || "",
          copyRecipient1: session?.emailCopies[0] || "",
          copyRecipient2: session?.emailCopies[1] || "",
          sessionType: session?.appointmentType || "",
          userPhone: session?.userPhone || "",
          userTimezone: session?.userTimezone || "",
        });
      if (!isDateEditable) {
        const initialDate = session?.scheduledAt?._seconds
          ? moment(session.scheduledAt._seconds * 1000).toDate()
          : null;
        setSessionDate(initialDate);
        setSelectedDate(initialDate);
      }

      if (!isSessionStageEditable) {
        setSelectedSessionStage({
          value: session?.stage,
          label: session?.stage,
        });
      }
      if (!isDocumentSealEditable)
        setSelectedDocumentSeal(session?.documentsCount);
      if (!isNotaryOwnerEditable)
        setSelectedNotaryOwner({
          label: session?.official?.name,
          value: session?.official?.name,
        });
      const isNotaryAdminTemp = session.official?.roles
        ? session?.official?.roles.some((item: string) =>
          ["notaryAdmin", "scheduler", "remoteSignSessionCreator"].includes(
            item
          )
        )
        : false;
      setIsNotaryAdmin(isNotaryAdminTemp);
    }
  }, [session]);

  useEffect(() => {
    if (isDateEditable) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          datePickerRef.current &&
          !datePickerRef.current.contains(event.target as Node)
        ) {
          setIsDateEditable(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isDateEditable]);

  const onJoin = (session: any, path?: any) => {
    if (!session.security) {
      console.error("No security credentials provided!");
      return false;
    }

    var link = createOfficialLink(notaryLink, session.security);
    if (path) {
      link += path;
    }

    window.open(link, "newwindow", "width=1200,height=1100");
  };

  const onResendDownloadPageLink = async () => {
    const notificationResponse = await dispatch(
      sendNotification({
        status: "created",
        sessionId: session?.id,
      })
    );

    if (notificationResponse.meta.requestStatus === "fulfilled") {
      toast.success("Download page link sent successfully!", {
        autoClose: 5000,
      });
    }
  }

  const onCopyDownloadPageLink = () => {
    const notaryLink = "https://demo.onlinenotary.us";
    const link = createUserLinkForCopyDownloadPageLink(notaryLink, session?.security);
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.info("Download page link copied to clipboard!", {
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.error("Failed to copy download page link.", {
          autoClose: 3000,
        });
      });
  }

  const onCopyReceiptLink = () => {
    const link = session.receiptUrl;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.info("Receipt link copied to clipboard!", {
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.error("Failed to copy receipt link.", {
          autoClose: 3000,
        });
      });
  }

  const onDownloadAllDocuments = async () => {
    await handleDownloadDocuments(documents)
  }

  const onSendSessionFeedback = () => {
    openSessionFeedback()
  }

  const changeStage = async (stageInParams: string) => {
    let stage = stageInParams || session.stage;
    try {
      if (!stage || stage === session.stage) {
        return;
      }
      if (
        [
          "idVerificationStart",
          "idVerificationFront",
          "mobileVerificationIdCapture",
        ].includes(stage)
      ) {
        await handleUpdateDetails(
          {
            sessionId: session.id,
            latestSubStage: "documentCapture",
            stage: stage,
            idFrontImage: null,
          },
          "upateDoc_id"
        );
        handleSessionSelect({
          ...session,
          sessionId: session.id,
          latestSubStage: "documentCapture",
          stage: stage,
          idFrontImage: null,
        });

      } else {
        await handleUpdateDetails(
          {
            stage: stage,
          },
          "upateDoc"
        );
        handleSessionSelect({
          ...session,
          stage: stage,
        });
        toast.success("Session stage has been changed to " + stage);
      }
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const onCancel = async (session: any) => {
    try {
      setIsCancelSessionLoading(true);
      await handleUpdateDetails(
        {
          stage: CANCELLED_STATUS,
        },
        "cancel"
      );
      handleSessionSelect({
        ...session,
        stage: CANCELLED_STATUS,
      });
      const notificationResponse = await dispatch(
        sendNotification({
          status: CANCELLED_STATUS,
          sessionId: session?.id,
          note: cancelSessionNote,
        })
      );
      if (notificationResponse.meta.requestStatus == "fulfilled") {
        setIsCancelSessionLoading(false);
        closeCancelSessionPopup();
        toast.success("Session cancelled successfully!", {
          autoClose: 5000,
        });
      }
      if (notificationResponse.meta.requestStatus == "rejected") {
        toast.error("Something went wrong", {
          autoClose: 5000,
        });
      }
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const onRestart = async (session: any) => {
    try {

      setIsRestartSessionLoading(true);
      await handleUpdateDetails(
        {
          stage: START_STATUS,
          sessionId: session?.id,
          idImageFront: null,
          selfieImage: null,
          idCaptureError: null,
          latestSubStage: "documentCapture",
          eventLog: null,
          idParts: null,
          documentType: null,
        },
        "restart"
      );
      handleSessionSelect({
        ...session,
        stage: START_STATUS,
        sessionId: session?.id,
        idImageFront: null,
        selfieImage: null,
        idCaptureError: null,
        latestSubStage: "documentCapture",
        eventLog: null,
        idParts: null,
        documentType: null,
      });

      setIsRestartSessionLoading(false);
      closeRestartSessionPopup();
      toast.success("Session restarted successfully!", {
        autoClose: 5000,
      });
    }
    catch (err: any) {
      console.log("err", err)
    }
  };

  const onEnableDisable = async (session: any) => {
    try {
      setIsEnableDisableSessionLoading(true);
      const isDisabled = !(session?.settings && session?.settings?.isMeetingDisabled);
      await handleUpdateDetails(
        {
          settings: {
            isMeetingDisabled: isDisabled,
          },
        },
        "settings"
      );
      handleSessionSelect({
        ...session,
        settings: {
          isMeetingDisabled: isDisabled,
        },
      });
      setIsEnableDisableSessionLoading(false);
      closeDisableAudioVideoSessionPopup();
      toast.success(
        `Audio/Video is successfully ${isDisabled ? "disabled" : "enabled"
        } for this session!`,
        {
          autoClose: 5000,
        }
      );
    }
    catch (err: any) {
      console.log("err", err)
    }
  };

  const callAction = useCallback(
    (action: string) => {
      if (action == "joinSession") {
        onJoin(session);
      }
      if (action == "cancelSession") {
        openCancelSessionPopup();
      }
      if (action == "restartSession") {
        openRestartSessionPopup();
      }
      if (action == "disableVideoAudio") {
        openDisableAudioVideoSessionPopup();
      }
      if (action == "copySession") {
        copySession();
      }
      if (action == "resendSessionLink") {
        resendSessionLink();
      }

    },
    [session]
  );

  const callCompletedAction = useCallback(
    (action: string) => {
      if (action == "resendDownloadPageLink") {
        onResendDownloadPageLink();
      }
      if (action == "copyDownloadPageLink") {
        onCopyDownloadPageLink();
      }
      if (action == "copyReceiptLink") {
        onCopyReceiptLink();
      }
      if (action == "downloadAllDocuments") {
        onDownloadAllDocuments();
      }
      if (action == "sendSessionFeedback") {
        onSendSessionFeedback();
      }
    },
    [session]
  );

  const copySession = () => {
    const notaryLink = "https://demo.onlinenotary.us";
    const link = createUserLinkForCopySessionInvite(notaryLink, session?.security);
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.info("Session invite link copied to clipboard!", {
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.error("Failed to copy session invite link.", {
          autoClose: 3000,
        });
      });

  }

  const resendSessionLink = async () => {
    console.log("resendSessionLink")
    const notificationResponse = await dispatch(
      sendNotification({
        status: "created",
        sessionId: session?.id,
      })
    );

    if (notificationResponse.meta.requestStatus === "fulfilled") {
      toast.success("Session link resent!", {
        autoClose: 5000,
      });
    }
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleUndo = async (previousData: any, type: string) => {
    const updateSessionDetailResponse = await dispatch(
      updateSessionDetail({
        details: {
          ...previousData,
        },
        sessionId: session?.id,
      })
    );
    if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
      if (type == "notes")
        setTextareaValue(previousData?.officialPrivateNotes)
      if (type == "notaryOwner")
        setSelectedDocumentSeal(previousData?.documentsCount)
      if (type == "scheduling") {
        const date = new Date(session?.scheduledAt);
        const scheduledAt = {
          _seconds: Math.floor(date.getTime() / 1000),
          _nanoseconds: (date.getMilliseconds() * 1e6)
        };
        previousData = {
          scheduledAt: session?.scheduledAt
        }
        // setSessionDate(session?.scheduledAt)
      }
      handleSessionSelect({
        ...session,
        ...previousData
      });
      toast.success("Changes have been undone", {
        autoClose: 5000,
      });
    }
    if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
      toast.error("Something went wrong", {
        autoClose: 5000,
      });
    }
  };
  const handleUpdateDetails = async (data: any, type: string) => {
    let previousState = {};

    // if (type == "upateDoc_id") {
    //   previousState = {
    //     stage: session?.stage,
    //     officialPrivateNotes: session?.officialPrivateNotes,
    //   };
    // }
    // if (type == "upateDoc") {
    //   previousState = {
    //     stage: session?.stage,
    //     officialPrivateNotes: session?.officialPrivateNotes,
    //   };
    // }
    if (type == "notes") {
      previousState = {
        officialPrivateNotes: session?.officialPrivateNotes,
      };
    }
    if (type == "sessionStage") {
      previousState = {
        stage: session?.stage,
      };
    }
    // if (type == "documentCounts") {
    //   previousState = {
    //     documentsCount: documents.length - 1,
    //   };
    // }
    // if (type == "sessionFeedback") {
    //   previousState = {
    //     officialFeedback: session?.stage,
    //   };
    // }

    if (type == "signerDetail") {
      let previousDetail = {};
      if (session?.payerEmail) {
        previousDetail = {
          ...previousDetail,
          payerEmail: session?.payerEmail,
        };
      }
      if (session?.emailCopies.length > 0) {
        previousDetail = {
          ...previousDetail,
          emailCopies: [...session?.emailCopies],
        };
      }
      if (session?.userTimezone) {
        previousDetail = {
          ...previousDetail,
          userTimezone: session?.userTimezone,
        };
      }
      if (session?.appointmentType) {
        previousDetail = {
          ...previousDetail,
          appointmentType: session?.appointmentType,
        };
      }
      if (session?.userPhone) {
        previousDetail = {
          ...previousDetail,
          userPhone: session?.userPhone,
        };
      }
      if (session?.user?.fullName) {
        previousDetail = {
          ...previousDetail,
          signerName: session?.user?.fullName,
        };
      }
      previousState = {
        ...previousDetail,
        multipleSigner: session?.multipleSigner === "Multiple Signers",
      };
    }
    if (type == "notaryOwner") {
      const date = new Date(
        session?.official?.commissionExpirationDate?._seconds * 1000
      );
      const formattedDate = date.toString();
      previousState = {
        official: {
          ...session?.official,
          commissionExpirationDate: formattedDate,
        },
        documentsCount: session?.documentsCount,
      };
    }
    if (type == "cancel") {
      previousState = {
        stage: session?.stage,
      };
    }
    if (type == "restart") {
      previousState = {
        stage: session?.stage,
        sessionId: session?.id,
        idImageFront: session?.idImageFront,
        selfieImage: session?.selfieImage,
        idCaptureError: session?.idCaptureError,
        latestSubStage: session?.latestSubStage,
        eventLog: session?.eventLog,
        idParts: session?.idParts,
        documentType: session?.documentType,
      };
    }
    if (type == "scheduling") {
      const date = new Date(session?.scheduledAt?._seconds * 1000);
      previousState = {
        scheduledAt: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      };
    }
    if (type == "settings") {
      previousState = {
        settings: {
          isMeetingDisabled: session?.isMeetingDisabled,
        },
      };
    }

    const updateSessionDetailResponse = await dispatch(
      updateSessionDetail({
        details: {
          ...data,
        },
        sessionId: session?.id,
      })
    );
    if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
      setIsNoteEditable(false);
      setIsSessionStageEditable(false);
      setIsNameEditable(false);
      setIsDateEditable(false);
      setIsSessionDetailsEditable(false);
      if (type !== "upateDoc" && type !== "upateDoc_id" && type !== "restart" && type != "cancel" && type != "settings" && type != "documentsCount") {
        toast.success(
          ({ closeToast }: ToastContentProps) => (
            <div>
              <span>{type == "sessionFeedback" ? "Feedback has been sent" : "Details have been updated"}</span>
              {type !== "sessionFeedback" && (
                <button
                  onClick={() => {
                    handleUndo(previousState, type);
                    closeToast();
                  }}
                  style={{
                    marginLeft: "10px",
                    background: "none",
                    border: "none",
                    color: "#007bff",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Undo
                </button>
              )}
            </div>
          ),
          {
            autoClose: 5000,
          }
        );
      }
    }
    if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
      toast.error("Something went wrong", {
        autoClose: 5000,
      });
      throw new Error("Something went wrong");
    }
  };

  const handleTextareaFocus = () => {
    setIsNoteEditable(true);
    // adjustTextareaHeight();
  };

  const handleTextareaBlur = () => {
    // adjustTextareaHeightWhenBlur();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    // adjustTextareaHeight();

    // if (textareaRef.current) {
    //   textareaRef.current.style.height = "auto";
    //   textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    // }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleCancelClick = () => {
    setIsSessionStageEditable(false);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSessionDetailsSave = async () => {
    try {

      const updateData: any = {};

      if (updatedStage) {
        updateData.stage = updatedStage.value;
      }

      await handleUpdateDetails(updateData, "sessionDetail");
      handleSessionSelect({
        ...session,
        ...updateData
      })
      setIsSessionDetailsEditable(false);
      setUpdatedStage(null);
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const handleSessionDetailsCancel = () => {
    setIsSessionDetailsEditable(false);
    setUpdatedStage(null);
    setUpdatedDocumentSeal(null);
    setSelectedSessionStage(session?.stage);
    setSelectedDocumentSeal(session?.documentsCount);
  };
  const openPersona = () => {
    if (!personaClientRef.current) {
      personaClientRef.current = new window.Persona.Client({
        templateVersionId: "itmplv_D7q7BZVKY8VjwZbzuV3yJ4GwoT7K",
        referenceId: session.id,
        environmentId: "env_x1dkEzeHTUvG8Vh9rdEjTSpdSnkE", //production
        onComplete: ({ inquiryId, status, fields }: any) => {
          if (status === "completed") {
            completeVerification(fields);
          } else if (status === "failed") {
            const errorMessage = "ID Verification Failed";
            toast.error(errorMessage, {
              autoClose: 5000,
            });
          }
        },
      });

      personaClientRef.current.open();
    } else {
      personaClientRef.current.open();
    }
  };

  const completeVerification = async (fields: any) => {
    const documentId = fields["current-government-id"].value.id;
    var zipCode = fields["address-postal-code"].value;

    var address1 = fields["address-street-1"].value;
    var state = fields["address-subdivision"].value;
    var city = fields["address-city"].value;
    var dob = fields["birthdate"].value;
    var dl = fields["identification-number"].value;
    var idExpiration = fields["expiration-date"].value;
    var firstName = fields["name-first"].value;
    var lastName = fields["name-last"].value;
    var documentType = `${fields["selected-country-code"].value} ${fields[
      "identification-class"
    ].value.toUpperCase()}`;
    const updateData: {
      sessionId: any;
      userInfo: {
        zipCode: any;
        address1: any;
        city: any;
        state: any;
        dob: any;
        dl: any;
        firstName: any;
        lastName: any;
        documentType: string;
        idExpiration: any;
      };
      stage: string;
      idFrontImage?: string;
      latestSubStage?: string;
    } = {
      sessionId: session.id,
      userInfo: {
        zipCode: zipCode,
        address1: address1,
        city: city,
        state: state,
        dob: dob,
        dl: dl,
        firstName: firstName,
        lastName: lastName,
        documentType: documentType,
        idExpiration: idExpiration,
      },
      stage: session.isPersonallyKnown
        ? "mobileVerificationPersonalDetails"
        : "personalDetails",
    };
    var targetMarkets = [
      "Missouri",
      "Florida",
      "Montana",
      "LOUISIANA",
      "MINNESOTA",
      "PENNSYLVANIA",
      "NEWYORK_BIOMETRIC",
    ].map(function (m) {
      return m.toLowerCase();
    });

    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "Persona-Version": "2023-01-05",
        authorization:
          "Bearer persona_sandbox_efeb9f82-0a8c-4931-8d13-1a65711dcf15",
      },
    };

    fetch(`document?documentId=${documentId}`, options)
      .then((response) => response.json())
      .then(async (response) => {
        await fetch(
          JSON.parse(response).data.attributes["front-photo"].url
        ).then(async (response) => {
          const imageBuffer = await response.arrayBuffer();
          const data = btoa(
            new Uint8Array(imageBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const result = removeBase64Header(
            await resizeSignature(`data:image/png;base64,${data}`, {
              width: 1024,
              height: 1024,
              format: "image/jpeg",
              quality: 0.7,
            })
          );
          updateData.idFrontImage = result;
          if (
            session.official &&
            session.official.market &&
            targetMarkets.includes(session.official.market.toLowerCase())
          ) {
            updateData.latestSubStage = "selfieCapture";
            updateData.stage = "idVerificationFront";
          }
          if (personaClientRef.current) {
            personaClientRef.current.destroy();
            personaClientRef.current = null;
          }
          const updateSessionDetailResponse = await dispatch(
            updateSessionDetail({
              details: {
                ...updateData,
              },
              sessionId: session?.id,
            })
          );
          if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
            return updateSessionDetailResponse.payload;
          }
          if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
            toast.error("Something went wrong", {
              autoClose: 5000,
            });
          }
        });
      });
  };

  const openPersonaDashboard = async (inquiryId: any) => {
    const url = `https://app.withpersona.com/dashboard/inquiries/${inquiryId}`;
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const handleNotesCancel = () => {
    setTextareaValue(session?.officialPrivateNotes || "");
    adjustTextareaHeight();
    setIsNoteEditable(false);
  };

  const handleNotesSave = async () => {
    try {
      await handleUpdateDetails({ officialPrivateNotes: textareaValue }, "notes");
      handleSessionSelect({
        ...session,
        officialPrivateNotes: textareaValue
      })
      setIsNoteEditable(false);
      adjustTextareaHeight();
    }
    catch (err: any) {
      console.log("error", err)
    }
  };

  const handleSaveClick = async () => {
    if (updatedStage) {
      try {
        await handleUpdateDetails({ stage: updatedStage.value }, "sessionStage");
        handleSessionSelect({
          ...session,
          stage: updatedStage.value
        })
        setUpdatedStage(null);
        setIsSessionStageEditable(false);
      }
      catch (err: any) {
        console.log("error", err)
      }
    }
  };

  const handleDownloadDocuments = async (documents: any) => {
    try {
      await handleDownloadDoc(documents, session.id);
    } catch (err: any) {
      toast.error(err.message, { autoClose: 5000 });
    }
  };

  const handleDocument = async (documentId: string) => {
    const str = createOfficialLink(notaryLink, session?.security);
    const iframeUrlTemp =
      str.substring(0, str.indexOf("sid=")) + `#/document/${documentId}`;
    setIframeUrl(iframeUrlTemp);
    setDocumentId(documentId);
  };


  const showDocument = (document: any) => {
    const baseLink = createOfficialLink(notaryLink, session?.security);
    const docUrl = `${baseLink.split('sid=')[0]}#/document/${document.document_id}`;
    setEditIframeUrl(docUrl);

    // Simulate PDF preview and loading
    // updatePdf(document).then(() => {
    //   console.log('PDF loaded successfully');
    // });
  };

  const updateSessionDetails = async (updatedSession: any, force = false) => {
    setPdfPreviewCanvasId(`preview-document-canvas-${new Date().getTime()}`)

    if (!updatedSession) return;

    // setSessionData(updatedSession);

    if (force || !updatedSession.documents) {
      // if (window.location.pathname === ONE_RECORD_PRODUCTION_ROUTE_NAME) {
      //   requestParams.email = EnvironmentService.email();
      // }
      const getDocumentResponse = await dispatch(
        getDocument({ sessionId: updatedSession.id })
      );

      if (getDocumentResponse.meta.requestStatus == API_STATUS.fulfilled) {
        const documentIdTemp = getDocumentResponse?.payload;
        if (getDocumentResponse?.payload?.length > 0) {
          showDocument(session?.payload[0])
        }
      }
      if (getDocumentResponse.meta.requestStatus == "rejected") {
        toast.error("Something went wrong", {
          autoClose: 5000,
        });
      }

    }


  };

  const checkSealedFile = useCallback(() => {
    setTimeout(async () => {
      const checkSessionStatusResponse = await dispatch(
        checkSessionStatus({
          sessionId: session?.id,
        })
      );
      if (checkSessionStatusResponse.meta.requestStatus === "fulfilled") {
        const data = checkSessionStatusResponse.payload
        if (!data || !data.sealedUrl) {
          checkSealedFile();
          return;
        }
        // moveTo(session, COMPLETED_STATUS)
        // displayByStatus(COMPLETED_STATUS);
        const updatedSession = { ...session, ...data };
        updateSessionDetails(updatedSession, true);
      }

    }, 5 * 1000);
  }
    , [session]);

  const closeRequestDocPopup = () => {
    setIsRequestDocPopupVisible(false);
  };

  const closeSessionFeedbackPopup = () => {
    setIsSessionFeedbackPopupVisible(false);
  };

  const handleUploadLinkPopupClose = () => {
    setIsUploadLinkPopupOpen(false);
    setIsRequestDocPopupVisible(false);
  };

  const handleUploadLinkPopuOpen = () => {
    setIsUploadLinkPopupOpen(true);
  };


  const handleViewRecord = async () => {
    try {
      // setIsRecordLoading(true);
      const getRecordResponse = await dispatch(getRecords({ status: "finished", sessionId: session?.id }));
      if (getRecordResponse.meta.requestStatus === API_STATUS.fulfilled) {
        if (getRecordResponse.payload?.length > 0) {
          setSessionRecords(getRecordResponse.payload)
          setIsViewRecordModalOpen(true)
        }
        else {
          toast.error("No record found", { autoClose: 5000 })
        }
        // setAcknowledgments([...acknowledges]);
        // setFilteredRecords([...acknowledges]);
      }
      if (getRecordResponse.meta.requestStatus === API_STATUS.rejected) {
        console.error("Error fetching records");
      }
    } catch (error) {
      console.error("Error in get records", error);
    } finally {
      // setIsRecordLoading(false);
    }
  }

  // const getNotarySessionsResource = ()=> {
  //   // if (notarySessionsResource) {
  //   //     return $q.resolve(notarySessionsResource);
  //   // }

  //   return NotaryResourceFactory
  //       .createSessionsResource({
  //           officialId: this.officialId,
  //           firmId: this.firmId
  //       })
  //       .then(function (resource) {
  //           notarySessionsResource = resource;

  //           return notarySessionsResource;
  //       });
  //   };

  // const checkSessionStatus = function (sessionId) {
  //   return getNotarySessionsResource()
  //       .then(function (notarySessionsResource) {
  //           return notarySessionsResource.status({ sessionId: sessionId }).$promise;
  //       });
  //   };

  // const check = () => {
  //   setTimeout(async () => {
  //     const checkResult = await checkSessionStatus();

  //     if (checkResult) {
  //       window.location.reload();
  //     } else {
  //       check();
  //     }
  //   }, 5000);
  // };
  const check = useCallback(async () => {
    try {
      const checkSessionStatusResponse = await dispatch(
        checkSessionStatus({
          sessionId: session?.id,
        })
      );
      if (checkSessionStatusResponse.meta.requestStatus === "fulfilled") {
        const data = checkSessionStatusResponse.payload;
        if (!data.updatedAt) return false;
        if (!session.updatedAt) return true;
        if (data.updatedAt._seconds !== session.updatedAt._seconds) return true;

        return false;
      }
      else {
        return false
      }
    } catch (error) {
      console.error('Error checking session status:', error);
      return false;
    }
  }, [session]);


  console.log(":session", session)
  const handleCheck = useCallback(async () => {

    const checkAndReload = async () => {
      const checkResult = await check();
      if (checkResult) {
        window.location.reload();
      } else {
        setTimeout(checkAndReload, 5000);
      }
    };

    checkAndReload();
  }, [check]);

  const handleOpenSession = async (path: string) => {
    if (!session.security) {
      console.error("No security credentials provided!");
      return false;
    }

    var link = createOfficialLink(notaryLink, session.security);
    if (path) {
      link += path;
    }
    checkSealedFile();
    window.open(link, 'newwindow', 'width=1200,height=1100');

  }

  const editDocument = async (id: string) => {
    await changeStage("customEditDocument");
    await handleCheck();
    await handleOpenSession('/custom-edit-document?document-id=' + id)
  };

  const handleDeleteDoc = async () => {
    if (deleteDocId) {
      setIsDeleteDocLoading(true);
      try {
        const deleteDocumentResponse = await dispatch(
          deleteDocument({ documentId: deleteDocId })
        );
        if (deleteDocumentResponse.meta.requestStatus == API_STATUS.fulfilled) {
          await handleUpdateDetails(
            { documentsCount: documents.length - 1 },
            "documentsCount"
          );
          handleSessionSelect({
            ...session,
            documentsCount: documents.length - 1
          })
          closeDeleteDocPopup();
          let filteredDoc = documents.filter(doc => doc.id !== deleteDocId)
          setDocuments(filteredDoc)
        }
        else {
          setIsDeleteDocLoading(false);
          toast.error("Error while deleting", { autoClose: 5000 })
        }
      }
      catch (err: any) {
        setIsDeleteDocLoading(false)
      }
    }
  };

  return (
    <Container>
      <NotaryActionBox>
        <FirstColumn>
          <NameContainer>
            <NameAndType>
              {activeScheduleTab == "scheduled" ? (
                <Name>{session?.name}</Name>
              ) : (
                <NameBox>
                  <Name>{session?.name}</Name>
                  {completedSessionDate && (<CompletedSchedule><Completed>Completed: </Completed><CompletedTime>{moment(completedSessionDate).format("MMMM D, YYYY h:mm A")}</CompletedTime></CompletedSchedule>
                  )}
                </NameBox>
              )}
              {session?.multipleSigner && (
                <MultipleSignerBox>
                  <span>
                    Multiple signers
                  </span>
                </MultipleSignerBox>
              )}
            </NameAndType>

            <SessionActionDropdownBox>
              {activeScheduleTab == "completed" ? (
                <SessionActionCompletedDropdown
                  onChange={handleChangeCompletedAction}
                  callAction={callCompletedAction}
                  session={session}
                />
              ) : (
                <SessionActionDropdown
                  onChange={handleChangeAction}
                  callAction={callAction}
                  session={session}
                />
              )}

            </SessionActionDropdownBox>
          </NameContainer>
        </FirstColumn>
        <SecondColumn>
          <IntroDetail>
            <StyledDate onClick={handleDateEditClick}>
              {moment(sessionDate).format("MMMM D, YYYY h:mm A")}
            </StyledDate>
            {isDateEditable && (
              <DatePickerWrapper ref={datePickerRef}>
                <DatePicker
                  inline
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  timeIntervals={30}
                  timeFormat="h:mm aa"
                  timeCaption="Time"
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <ButtonGroupDate>
                  <Button onClick={handleCancelDateEdit} variant="cancel">
                    Cancel
                  </Button>
                  <Button onClick={handleSaveDate} variant="save">
                    {updateLoading ? <LoadingSpinner /> : "Save"}
                  </Button>
                </ButtonGroupDate>
              </DatePickerWrapper>
            )}
            <AppoitmentId onClick={handleCopyAppointmentId}>
              <AppoitmentIdTitle>Appointment ID </AppoitmentIdTitle>
              {session?.appointmentId}
            </AppoitmentId>
          </IntroDetail>

          <NotaryOwnerBox>
            <NotaryTitle>Notary</NotaryTitle>
            <Dropdown
              onChange={handleChangeNotaryOwner}
              value={selectedNotaryOwner}
              options={officials.map((item) => {
                return { value: item.name, label: item.name };
              })}
              isSearchable={true}
            />
          </NotaryOwnerBox>

          <DocumentSealBox>
            <DocumentSealTitle>Document/seals</DocumentSealTitle>
            <Counter
              value={selectedDocumentSeal || 0}
              onChange={handleDocumentSealChange}
            />
          </DocumentSealBox>
        </SecondColumn>

        {(isNotaryOwnerEditable || isDocumentSealEditable) && (
          <ButtonGroup>
            <Button onClick={handleNotaryOwnerCancel} variant="cancel">
              Cancel
            </Button>
            <Button onClick={handleNotaryOwnerSave} variant="save">
              {updateLoading ? <LoadingSpinner /> : "Save"}
            </Button>
          </ButtonGroup>
        )}
      </NotaryActionBox>

      <NotesActionBox>
        <NotesSessionStageBox>
          <SubColoumn style={{ flex: "2 1 67%" }}>
            <NotesSubBox>
              <NotesContentBox>
                <NotesHeader>
                  <NotesTitle>Notes</NotesTitle>
                </NotesHeader>

                <FloatingLabelTextarea
                  ref={textareaRef}
                  label=""
                  value={textareaValue}
                  onChange={handleTextareaChange}
                  onFocus={handleTextareaFocus}
                  onBlur={handleTextareaBlur}
                  readOnly={!isNotesEditable}
                  maxLength={300}
                  rows={1}
                />
              </NotesContentBox>
              <WordCount>
                {textareaValue.length} / {300}
              </WordCount>
            </NotesSubBox>
            {isNotesEditable && (
              <ButtonGroup>
                <Button onClick={handleNotesCancel} variant="cancel">
                  Cancel
                </Button>
                <Button onClick={handleNotesSave} variant="save">
                  {updateLoading ? <LoadingSpinner /> : "Save"}
                </Button>
              </ButtonGroup>
            )}
          </SubColoumn>
          <SubColoumn style={{ flex: "1 1 33%" }}>
            <StageColumn>
              <StageColumnName>Session Stage</StageColumnName>
              <SessionStageBox>
                <Dropdown
                  onChange={handleChangeSessionStage}
                  value={selectedSessionStage}
                  options={SESSION_STAGES}
                  isSearchable={true}
                />
              </SessionStageBox>
            </StageColumn>
            {isSessionStageEditable && (
              <ButtonGroup>
                <Button onClick={handleCancelClick} variant="cancel">
                  Cancel
                </Button>
                <Button onClick={handleSaveClick} variant="save">
                  {updateLoading ? <LoadingSpinner /> : "Save"}
                </Button>
              </ButtonGroup>
            )}
          </SubColoumn>
        </NotesSessionStageBox>
      </NotesActionBox>

      <SignerDetailBox>
        <SignerTitle>
          Signer Details
          {!isSignerDetailEditable && (
            <EditIcon onClick={handleSignerDetailEditClick} />
          )}
        </SignerTitle>
        <Columns>
          {Object.keys(signerDetail).map((key, index) => (
            <Column key={index}>
              <ColumnName>
                {labelMapping[key] || key.split(/(?=[A-Z])/).join(" ")}
              </ColumnName>
              {isSignerDetailEditable && !["email"].includes(key) ? (
                key === "sessionType" ? (
                  <Dropdown
                    options={sessionTypes}
                    value={selectedSessionType}
                    onChange={handleSessionTypeChange}
                  />
                ) : // : key === "signers" ? (
                  //   <Dropdown
                  //     options={signerOptions}
                  //     value={
                  //       signerOptions.find((option) => option.value === signerDetail.signers) || signerOptions[0] // Provide a default option
                  //     }
                  //     onChange={(newValue: SingleValue<OptionType>) =>
                  //       setSignerDetail((prevState) => ({
                  //         ...prevState,
                  //         signers: newValue?.value || "Single Signer",
                  //       }))
                  //     }
                  //   />

                  // )
                  key === "userTimezone" ? (
                    <Dropdown
                      value={{
                        value: (signerDetail as any)[key] || "",
                        label: TIME_ZONES.find(zone => zone.value === (signerDetail as any)[key])?.name || "Select Time Zone",
                      }}
                      onChange={(newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
                        handleSignerDetailChange({
                          target: {
                            name: key,
                            value: newValue?.value || "",
                          },
                        } as React.ChangeEvent<HTMLInputElement>);
                      }}
                      styles={{
                        option: {
                          fontSize: "14px",
                        },
                      }}
                      options={TIME_ZONES.map((zone) => ({
                        value: zone.value,
                        label: zone.name,
                      }))}
                      isSearchable={true}
                    />
                  ) : (
                    <ColumnValue
                      name={key}
                      value={(signerDetail as any)[key]}
                      onChange={handleSignerDetailChange}
                      readOnly={!isSignerDetailEditable}
                    />
                  )

              ) : (
                <ColumnValue
                  isEditable={false}
                  readOnly
                  value={(signerDetail as any)[key]}
                />
              )}
            </Column>
          ))}
        </Columns>
        {isSignerDetailEditable && (
          <ButtonGroupSignerDetail>
            <Button onClick={handleSignerDetailCancel} variant="cancel">
              Cancel
            </Button>
            <Button onClick={handleSignerDetailSave} variant="save">
              {updateLoading ? <LoadingSpinner /> : "Save"}
            </Button>
          </ButtonGroupSignerDetail>
        )}
      </SignerDetailBox>
      <AppoitmentDetailBox>
        <SignerTitle>Session Details</SignerTitle>
        <AppoitmentColumns>
          <Column>
            <ColumnName>Credential Analysis</ColumnName>
            <Badge>{getCredentialAnalysisStatus(session).text}</Badge>
          </Column>
          <Column>
            <ColumnName>Identify Proofing</ColumnName>
            <Badge>{getQuizStatus(session).text}</Badge>
          </Column>

          {session?.userGeo && (
            <Column>
              <ColumnName>Signer Location</ColumnName>
              {session.userGeo.country +
                ", " +
                session.userGeo.city +
                ", " +
                session.userGeo.regionName +
                ", " +
                session.userGeo.zip}
            </Column>
          )}
          {session?.userGeo?.isp && (
            <Column>
              <ColumnName>Signer Network</ColumnName>
              {session?.userGeo?.isp}
            </Column>
          )}

          {getDevice(session) && (
            <Column>
              <ColumnName>Signer Device</ColumnName>
              {getDevice(session)}
            </Column>
          )}
        </AppoitmentColumns>

        {isSessionDetailsEditable && (
          <ButtonGroupSignerDetail>
            <Button onClick={handleSessionDetailsCancel} variant="cancel">
              Cancel
            </Button>
            <Button onClick={handleSessionDetailsSave} variant="save">
              {updateLoading ? <LoadingSpinner /> : "Save"}
            </Button>
          </ButtonGroupSignerDetail>
        )}
      </AppoitmentDetailBox>

      <TabBox>
        <Tabs>
          <Tab
            isActive={activeTab === "Session Documents"}
            onClick={() => setActiveTab("Session Documents")}
          >
            Session Documents
          </Tab>

          <Tab
            isActive={activeTab === "Session Status"}
            onClick={() => setActiveTab("Session Status")}
          >
            Session Status
          </Tab>
          <IconsBox>
            {activeScheduleTab == "scheduled" ? (
              <>
                <NewDocumentIcon
                  onClick={() => openAddDocPopup()}
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <RequestIcon
                  onClick={handleRequestDoc}
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <DownloadIcon
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  onClick={(event) => handleDownloadDocuments(documents)}
                /></>
            ) : (
              <>

                <DownloadIcon
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  onClick={(event) => handleDownloadDocuments(documents)}
                /></>
            )
            }

          </IconsBox>
        </Tabs>
        <TabContent>
          {activeTab == "Session Documents" ? (
            <>
              {iframeUrl && documentId && (
                <>
                  <SessionDocumentsContainer>
                    <IframeHeader>
                      {/* <SessionDocumentHeading>
                        Session document
                      </SessionDocumentHeading> */}
                      {activeScheduleTab == "scheduled" ? (
                        <ParticularDoc>
                          {documents.map((document: any, index: number) => {
                            return (
                              <IframeHeaderBox
                                onClick={() =>
                                  handleDocument(document?.document_id)
                                }
                                isLast={documents.length === index + 1}
                              >
                                <IframeDocSubBox
                                  isActive={documentId === document?.document_id}
                                >
                                  <DocName>{document?.name}</DocName>
                                  <EditDeletIconBox>
                                    {!session.isCompleted && (
                                      <EditIconSvg
                                        style={{ width: "23px", height: "23px" }}
                                        onClick={() => editDocument(document?.document_id)}
                                      />
                                    )}

                                    {(!session.isCompleted && documents?.length > 1) && (
                                      <TrashIcon
                                        style={{ width: "23px", height: "23px" }}
                                        onClick={() => {
                                          openDeleteDocPopup();
                                          setDeleteDocId(document?.id)
                                        }}
                                      />
                                    )}

                                  </EditDeletIconBox>
                                </IframeDocSubBox>
                              </IframeHeaderBox>
                            );
                          })}
                        </ParticularDoc>
                      ) : (
                        <ParticularDoc>
                          {documents.map((document: any, index: number) => {
                            return (
                              <IframeHeaderContainer>
                                <IframeHeaderBox
                                  onClick={() =>
                                    handleDocument(document?.document_id)
                                  }
                                  isLast={documents.length === index + 1}
                                >
                                  <IframeDocSubBox
                                    isActive={documentId === document?.document_id}
                                  >
                                    <DocName>{document?.name}</DocName>
                                  </IframeDocSubBox>
                                  <BookIconBox onClick={handleViewRecord}>
                                    <BookIcon
                                      style={{ width: "23px", height: "23px" }}
                                    />
                                  </BookIconBox>
                                </IframeHeaderBox>
                              </IframeHeaderContainer>
                            );
                          })}
                        </ParticularDoc>
                      )}

                    </IframeHeader>
                  </SessionDocumentsContainer>
                  <StyledIframe
                    ng-show="showCanvas"
                    ng-src={iframeUrl}
                    aria-hidden="false"
                    className=""
                    src={iframeUrl}
                  ></StyledIframe>

                </>
              )}
            </>
          ) : (
            <>
              <SessionStatusBox>
                <FirstColumnSessioStatus>
                  <Row>
                    <RowName>Principal:</RowName>
                    <RowText>{session?.user?.fullName || ""}</RowText>
                  </Row>

                  <Row>
                    <RowName>Credential analysis:</RowName>
                    <RowText>
                      {getCredentialAnalysisStatus(session).text}
                    </RowText>
                  </Row>

                  <Row>
                    <RowName>Identity proofing quiz:</RowName>
                    <RowText>{getQuizStatus(session).text}</RowText>
                  </Row>
                  {getDevice(session) && (
                    <Row>
                      <RowName>Principal device:</RowName>
                      <RowText>{getDevice(session)}</RowText>
                    </Row>
                  )}
                  <Gap />
                  <Row>
                    <RowName>Notary in-session?</RowName>
                    {isOfficialOnline ? <OnlineRowSign /> : <OfflineRowSign />}
                    {(isOfficialOnline && session?.officialConnectivity?.level) && (
                      <RowText>
                          {session?.officialConnectivity?.level} / 5  <FaSignal />
                      </RowText>
                    )}

                  </Row>

                  <Row>
                    <RowName>Signer in-session?</RowName>
                    {isSignerOnline ? <OnlineRowSign /> : <OfflineRowSign />}
                    {(isSignerOnline && session?.signerConnectivity?.level) && (
                      <RowText>
                          {session?.signerConnectivity?.level} / 5  <FaSignal />
                      </RowText>
                    )}
                  </Row>

                  <Row>
                    <RowName>Signer video status:</RowName>
                    {session.userVideoJoined ? <OnlineRowSign /> : <OfflineRowSign />}
                    {(session?.signerConnectivity && session?.signerConnectivity?.error) && (
                      <RowText>WebRTC Error: {session?.signerConnectivity?.error}</RowText>
                    )}
                    
                  </Row>

                  <Row>
                    <RowName>Session stage:</RowName>
                    <RowText>{session?.stage}</RowText>
                  </Row>
                  <Gap />
                  {/* <Row>
                    <RowName>Signer email:</RowName>
                    <RowText>{session?.user?.email || ""}</RowText>
                  </Row> */}
                  {session?.userPhone && (
                    <Row>
                      <RowName>Signer phone:</RowName>
                      <RowText>{session?.userPhone || ""}</RowText>
                    </Row>
                  )}
                  {signerDetail?.userTimezone && (
                    <Row>
                      <RowName>Signer timezone:</RowName>
                      <RowText>{signerDetail?.userTimezone}</RowText>
                    </Row>
                  )}
                  <Gap />
                </FirstColumnSessioStatus>
                <SecondColumnSessioStatus>
                  {session?.idFrontImage && (
                    <UploadedImgContainer>
                      <UploadedImage
                        src={session?.idFrontImage as string}
                        alt="Uploaded"
                      />
                    </UploadedImgContainer>
                  )}
                  <UploadButton>
                    <UploadIdButton onClick={() => openPersona()}>
                      Upload ID
                    </UploadIdButton>
                    {isNotaryAdmin && session?.inquiryId && (
                      <PersonaDashboardText onClick={openPersonaDashboard}>
                        Persona Dashboard
                      </PersonaDashboardText>
                    )}

                    <input
                      id="fileInput"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </UploadButton>
                </SecondColumnSessioStatus>
              </SessionStatusBox>
              {iframeUrl && documentId && (
                <StyledIframe
                  ng-show="showCanvas"
                  ng-src={iframeUrl}
                  aria-hidden="false"
                  className=""
                  src={iframeUrl}
                ></StyledIframe>
              )}
            </>
          )}
        </TabContent>
      </TabBox>
      {editIframeUrl && <StyledIframe
        ng-show="showCanvas"
        ng-src={editIframeUrl}
        aria-hidden="false"
        className=""
        src={editIframeUrl}
      ></StyledIframe>}


      {isCancelSessionPopupVisible && (
        <PopupOverlay onClick={closeCancelSessionPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupHeader>Cancel Notary Session</PopupHeader>
            <InputWrapper>
              <InputField
                onFocus={() => setIsCancelSessionInputFocused(true)}
                onBlur={() => setIsCancelSessionInputFocused(false)}
                aria-label="Include note to principal"
                value={cancelSessionNote}
                onChange={(e) => setCancelSessionNote(e.target.value)}
              />
              <InputLabel
                isFocused={
                  isCancelSessionInputFocused || Boolean(session?.note)
                }
              >
                Include note to principal
              </InputLabel>
            </InputWrapper>
            <ButtonCheckBox>
              <CheckboxContainer>
                <input type="checkbox" id="emailCheck" />
                <label htmlFor="emailCheck">Do not email principal</label>
              </CheckboxContainer>
              <ButtonGroup>
                <Button onClick={() => onCancel(session)} variant="save">
                  {isCancelSessionLoading ? (
                    <LoadingSpinner />
                  ) : (
                    "Cancel Session"
                  )}
                </Button>
                <Button onClick={closeCancelSessionPopup}>Cancel</Button>
              </ButtonGroup>
            </ButtonCheckBox>
          </PopupContainer>
        </PopupOverlay>
      )}
      {isRestartSessionPopupVisible && (
        <PopupOverlay onClick={closeRestartSessionPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupDescription>
              Are you sure you want to restart the session ?
            </PopupDescription>

            <ButtonCheckBox>
              <RestartButtonGroup>
                <Button onClick={() => onRestart(session)} variant="save">
                  {isRestartSessionLoading ? <LoadingSpinner /> : "Yes"}
                </Button>
                <Button onClick={closeRestartSessionPopup}>No</Button>
              </RestartButtonGroup>
            </ButtonCheckBox>
          </PopupContainer>
        </PopupOverlay>
      )}
      {isDeleteDocPopupVisible && (
        <PopupOverlay onClick={closeRestartSessionPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupDescription>
              Are you sure you want to delete this doc ?
            </PopupDescription>

            <ButtonCheckBox>
              <RestartButtonGroup>
                <Button onClick={() => handleDeleteDoc()} variant="save">
                  {isDeleteDocLoading ? <LoadingSpinner /> : "Yes"}
                </Button>
                <Button onClick={closeDeleteDocPopup}>No</Button>
              </RestartButtonGroup>
            </ButtonCheckBox>
          </PopupContainer>
        </PopupOverlay>
      )}
      {isDisableAudioVideoSessionPopupVisible && (
        <PopupOverlay onClick={closeDisableAudioVideoSessionPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupDescription>
              Are you sure you want to{" "}
              {session?.settings && session?.settings?.isMeetingDisabled
                ? "enable"
                : "disable"}{" "}
              Audio/Video for this session?
            </PopupDescription>

            <ButtonCheckBox>
              <DisableAudioVideoButtonGroup>
                <Button onClick={() => onEnableDisable(session)} variant="save">
                  {isEnableDisableSessionLoading ? <LoadingSpinner /> : "Yes"}
                </Button>
                <Button onClick={closeDisableAudioVideoSessionPopup}>No</Button>
              </DisableAudioVideoButtonGroup>
            </ButtonCheckBox>
          </PopupContainer>
        </PopupOverlay>
      )}

      {isRequestDocPopupVisible && (
        <RequestDocumentPopup
          onClose={closeRequestDocPopup}
          session={session}
          onSuccess={handleUploadLinkPopuOpen}
        />
      )}
      {isSessionFeedbackPopupVisible && (
        <SendFeedbackPopup
          onClose={closeSessionFeedbackPopup}
          onSuccess={handleSessionFeedback}
        />
      )}
      {isUploadLinkPopupOpen && (
        <UploadLinkPopup
          onClose={handleUploadLinkPopupClose}
          session={session}
        />
      )}
      {isViewRecordModalOpen && (
        <AcknowledgmentModal
          open={isViewRecordModalOpen}
          acknowledgments={sessionRecords}
          onClose={() => setIsViewRecordModalOpen(false)}
        />
      )}
      <StepTwoPopup
        open={isAddDocPopupOpen}
        onClose={closeAddDocPopup}
        sessionId={session?.id}
      />
    </Container>
  );
};

export default SessionSummary;

const Container = styled.div`
  flex: 73;
  height: fit-content;
  background: #f9f9f9;
  padding: 20px;
  padding-top: 0px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex: none;
    width: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
`;

const MultipleSignerBox = styled.div`
  display: flex;
  gap: 10px;
  background: #f6e5e2;
  padding:7px 10px;
  color: #ca3016;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 120vh;
  border: none;
  overflow: hidden;
`;

const IconsBox = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  gap: 18px;
  margin-left: auto;
  align-self: center;
  margin-right: 20px;
`;

const IframeHeaderBox = styled.div<{ isLast: boolean }>`
  display: flex;
  overflow: hidden; /* Hides scrollbars */
  border-bottom: ${(props) =>
    props.isLast ? "1px solid #ccc" : "1px solid #ccc"};
  padding-bottom: 6px;
  padding-top: 6px;
  gap: 10px;
`;

const IframeHeaderContainer = styled.div`
  
`;

const IframeDocSubBox = styled.div<{ isActive: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  overflow: hidden; /* Hides scrollbars */
  padding-top: 10px;
  padding-bottom: 10px;
  background: ${(props) => (props.isActive ? "#F1F1F1" : "white")};
  padding: 10px;
`;

const IframeHeader = styled.div`
  flex: 7;
`;

const SessionDocumentsContainer = styled.div`
  display: flex;
  gap: 60px;
`;

const ParticularDoc = styled.div`
  margin-bottom: 30px;
`;

const DocName = styled.span`
  display: flex;
  font-size: 14.4px;
  align-items: center;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

const EditDeletIconBox = styled.div`
  display: flex;
  gap: 15px;
  cursor: pointer;
`;

const BookIconBox = styled.div`
  display: flex;
  gap: 15px;
  cursor: pointer;
  align-items: center;
`;

const EditIcon = styled(MdOutlineEdit)`
  color: #007bff;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.dark};
  }
`;

const NotaryOwnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  flex: 1;
`;

const NotaryTitle = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  // font-weight: 600;
  font-size: 17px;
`;

const DocumentSealTitle = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  // font-weight: 600;
  font-size: 17.2px;
  max-width: 150px;
`;

const NotaryActionBox = styled.div`
  background: white;
  padding: 20px;
  // padding-top: 0px;
  padding-bottom: 15px;
  // overflow: hidden; /* Hides scrollbars */
  border-radius: 10px;
`;

const FirstColumn = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: ${(props) => `2px solid ${props.theme.colors.normal}`};
`;

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const NameAndType = styled.div`
  display: flex;
  gap: 60px;
`;

const NameBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  gap: 10px;
`;

const CompletedSchedule = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;

const NameInput = styled.input`
  font-size: 22px;
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  width: 100%;
`;

const Name = styled.span`
  font-size: 31.5px;
  color: black;
  font-weight: 800;
`;

const Completed = styled.span`
  font-size: 14.4px;
  color: #3FC163;
  font-weight: 500;
  `;

const CompletedTime = styled.span`
  font-size: 14.4px;
  color: #3FC163;
  font-weight: 500;

`;

const SessionActionDropdownBox = styled.div`
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(58, 58, 58, 0.18);
`;

const SecondColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 22px;
  margin-bottom: 2px;
  border-radius: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px 30px;
  }
`;

const IntroDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`;
const StyledDate = styled.span`
  display: flex;
  font-size: 14.4px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;
const AppoitmentId = styled.span`
  display: flex;
  font-size: 14.4px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
`;
const AppoitmentIdTitle = styled.span`
  font-size: 14.4px;
  font-weight: 600;
  margin-right: 8px;
`;

const SignerDetailBox = styled.div`
  padding: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 18px;
  border-radius: 10px;
  border-left: 7px solid #c1d3f5;
`;

const AppoitmentDetailBox = styled(SignerDetailBox)``;

const SignerTitle = styled.h3`
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 16px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const AppoitmentColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 32px;
  column-gap: 16px;
  width: 100%;
  @media (max-width: 1200px) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const StageColumn = styled(Column)`
  background: white;
  padding: 20px;
  flex-direction: row;
  border-radius: 10px;
  gap: 10px;
  max-height: 35px;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.greyText};
`;

const SubColoumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const Row = styled.div`
  display: flex;
  gap: 7px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 9px;
  align-items:center;
`;

const SessionStatusBox = styled.div`
  display: flex;
  overflow: hidden; /* Hides scrollbars */
`;

const FirstColumnSessioStatus = styled.div`
  flex: 1;
`;

const UploadButton = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  height: fit-content;
  align-items: center;
  margin-top: 20px;
`;

const SecondColumnSessioStatus = styled.div`
  flex: 1;
`;

const Gap = styled.div`
  height: 22px;
`;

const SessionStageBox = styled.div`
  flex: 1;
`;

const DocumentSealBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  flex: 1;
`;

const ColumnName = styled.div`
  color: ${(props) => props.theme.colors.greyText};
  font-size: 14px;
`;

const StageColumnName = styled(ColumnName)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.greyText};
  font-size: 14px;
`;

const NetworkLocation = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowText = styled.span`
  color: grey;
  font-size: 14px;
`;

const OnlineRowSign = styled.span`
  border-radius: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #00b050;

`;

const OfflineRowSign = styled.span`
  border-radius: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #920606;
`;

const PersonaDashboardText = styled.span`
  color: grey;
  font-size: 14px;
  height: fit-content;
`;

const ColumnValue = styled.input<{ isEditable?: boolean }>`
  color: black;
  font-size: 14.4px;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: scroll;
  background-color: #f9f9f9;
  cursor: ${(props) => (props.isEditable ? "text" : "default")};
  scrollbar-width: none; /* Hides scrollbars */
  &::-webkit-scrollbar {
    display: none; /* Hides scrollbars */
  }
`;

const RowName = styled.span`
  color: black;
  font-size: 14.4px;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: not-allowed;
`;

const Badge = styled.span`
  background-color: #c1f5d0;
  border-radius: 8px;
  padding: 6px 18px;
  height: 16px;
  font-size: 12px;
  white-space: nowrap;
  color: #249c46;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-contents: center;
  text-transform: uppercase;
`;

const TabBox = styled.div`
  background-color: white;
  border-radius: 10px;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
  border-bottom: 2px solid #ccc;
  border-bottom-color: #ccc;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 18px 55px;
  color: ${(props) => (props.isActive ? "#0F459F" : "#545F71")};
  text-align: center;
  font-size: 14.4px;
  cursor: pointer;
  border-bottom: ${(props) => (props.isActive ? "2px solid #007bff" : "none")};
  border-bottom-color: ${(props) => (props.isActive ? "#007bff" : "none")};
`;

const TabContent = styled.div`
  padding: 20px;
  padding-top: 20px;
  background-color: white;
  margin-top: 25px 30px;
  overflow: hidden; /* Hides scrollbars */
  border-radius: 10px;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupHeader = styled.h2`
  text-align: center;
`;

const PopupDescription = styled.h4`
  text-align: left;
  margin-right: auto;
  font-weight: 400;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ButtonCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 10px;
  gap: 10px;
`;

const ButtonGroupName = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ButtonGroupSignerDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 12px;
`;

const RestartButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const DisableAudioVideoButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px;
`;

const InputLabel = styled.label<{ isFocused: boolean }>`
  position: absolute;
  left: ${(props) => (props.isFocused ? "12px" : "24px")};
  top: ${(props) => (props.isFocused ? "-10px" : "20px")};
  color: ${(props) => (props.isFocused ? "#007bff" : "grey")};
  font-size: ${(props) => (props.isFocused ? "10.9px" : "14.4px")};
  transition: all 0.3s ease;
  pointer-events: none;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14.4px;
  padding-left: 12px;
  box-sizing: border-box;

  &:focus + ${InputLabel} {
    top: -10px;
    font-size: 12px;
    color: #007bff;
  }
`;

const NotesActionBox = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 18px;
  border-radius: 10px;
  padding-bottom: 2px;
  // overflow: hidden; /* Hides scrollbars */
`;

const NotesSessionStageBox = styled.div`
  display: flex;
  // background: white;
  margin-top: 18px;
  border-radius: 10px;
  padding: 0px 0px;
  gap: 30px;
  // overflow: hidden; /* Hides scrollbars */
`;

const NotesContentBox = styled.div`
  display: flex;
  gap: 10px;
  // background: white;
  // margin-top: 18px;
  // border-radius: 10px;
  // padding: 25px 30px;
  // overflow: hidden; /* Hides scrollbars */
`;

const NotesSubBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: flex-end;
  background: white;
  // margin-top: 18px;
  // border-radius: 10px;
  padding: 20px;
  padding-bottom: 4px;
  border-radius: 10px;
  // overflow: hidden; /* Hides scrollbars */
`;

const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: center;
  gap: 10px;
`;

const NotesTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.secondary};
  // font-weight: 600;
  font-size: 17.2px;
`;

const EditText = styled.span`
  color: ${(props) => props.theme.colors.grey};
  cursor: pointer;
  font-size: 10px;
  text-align: right;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const UploadIdButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: fit-content;
  background: #e7ecf5;
  color: #0f459f;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const UploadedImgContainer = styled.div`
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

const DateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  // top: 40px;
  // left: 0;

  .react-datepicker {
    display: flex;
  }
`;

const ButtonGroupDate = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const CustomDatePicker = styled(DatePicker)`
  .react-datepicker__time-container {
    width: 100px; /* Adjust width */
    margin: 0;
    padding: 0;
    background: white;
  }

  .react-datepicker__time-list {
    padding: 0;
    width: 100%; /* Make sure the time list takes full width */
  }

  .react-datepicker__time-list-item {
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
    background: white;
  }

  .react-datepicker__time-list-item--selected {
    background: #007bff;
    color: white;
  }
`;

const StyledSelect = styled.select`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  background: url("path/to/your/custom/dropdown-icon.png") no-repeat right;
  background-position-x: calc(100% - 10px);
`;

const WordCount = styled.div`
  font-size: 12px;
  color: grey;
  text-align: right;
`;
