import { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';

const useAuthCheck = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const checkUser = async () => {
      try {
        await getCurrentUser();
        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
      }
    };

    checkUser();
  }, []);

  return isLoggedIn;
};

export default useAuthCheck;
