import {
  configureStore,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { API_URL } from "./utils";
import { CANCELLED_STATUS } from "./constants/sessions";
import { getFromLocalStorage } from "./utils/localStorageUtil";

interface SessionState {
  sessions: {
    data: any;
    loading: boolean;
    error: any;
    activeSession: any;
  };
  unreadSessionRequests: number;
  updatingSession: {
    loading: boolean;
  };
}
const initialState: SessionState = {
  sessions: {
    data: [],
    loading: false,
    error: null,
    activeSession: null,
  },
  unreadSessionRequests: 0,
  updatingSession: {
    loading: false,
  },
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSessions.fulfilled, (state, action) => {
      state.sessions.data = action.payload.data;
      state.sessions.loading = false;
    });

    builder.addCase(getSessions.pending, (state) => {
      state.sessions.loading = true;
      state.sessions.data = [];
    });

    builder.addCase(getSessions.rejected, (state) => {
      state.sessions.loading = false;
      state.sessions.data = [];
    });
    builder.addCase(getNotarySessions.fulfilled, (state, action) => {
      var validBefore = 2 * 3600 * 1000;
      let filteredNotarySessions = action.payload?.items?.filter(
        (item: any) =>
          item?.isConfirmed === "1" &&
          new Date(item.requestedDatetimeDate).getTime() -
          new Date().getTime() <
          validBefore
      );
      state.unreadSessionRequests = filteredNotarySessions.length;
    });

    builder.addCase(sessionBatch.fulfilled, (state, action) => {
      const updatedSessions = action.payload.data;
      state.sessions.data = updatedSessions;
    });

    builder.addCase(sessionBatch.pending, (state) => {
      // state.updatingSession.loading = true;
    });

    builder.addCase(sessionBatch.rejected, (state) => {
      // state.updatingSession.loading = false;
    });
    builder.addCase(updateSessionDetail.fulfilled, (state, action) => {
      state.updatingSession.loading = false;
    });

    builder.addCase(updateSessionDetail.pending, (state) => {
      state.updatingSession.loading = true;
    });

    builder.addCase(updateSessionDetail.rejected, (state) => {
      state.updatingSession.loading = false;
    });
  },
});

export const getSessions = createAsyncThunk<
  any,
  { status: string; search?: string },
  { rejectValue: any }
>("session/getSessions", async ({ status, search }, { rejectWithValue }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    let routeUri =
      status === "completed" && search
        ? `/sessions?status=completed&search=${search}`
        : status === "completed"
          ? `/sessions?status=completed`
          : search
            ? `/sessions?status=active&search=${search}`
            : `/sessions?status=active`;

    const response = await fetch(API_URL + routeUri, {
      headers: {
        "X-Firm-Id": firmId,
        "X-Official-Id": officialId,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSessionsByAutoSearch = createAsyncThunk<
  any,
  { type: string; search: string, status: string },
  { rejectValue: any }
>("session/getSessions", async ({ type, search, status }, { rejectWithValue }) => {
  try {
    console.log("{ type, search, status }", { type, search, status })
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    let routeUri = `/sessions/autocomplete-search?type=${type}&search=${search}&status=${status}`

    const response = await fetch(API_URL + routeUri, {
      headers: {
        "X-Firm-Id": firmId,
        "X-Official-Id": officialId,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getNotarySessions = createAsyncThunk<
  any,
  {},
  { rejectValue: any }
>("session/getNotarySessions", async (_, { rejectWithValue }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    const response = await fetch(
      API_URL + "/document-groups?type=notaryRequest",
      {
        headers: {
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateRequestDetail = createAsyncThunk<
  any,
  { requestId: number; details: any },
  { rejectValue: any }
>(
  "session/updateRequestDetail",
  async ({ details, requestId }, { rejectWithValue }) => {
    try {
      let body = { ...details };
      console.log("body", body)
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + `/document-groups/${requestId}`, {
        method: "PUT",
        headers: {
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSession = createAsyncThunk<
  any,
  {
    documentsCount: number;
    emailCopies: any[];
    name: string;
    officialPrivateNotes: string | null;
    scheduledAt: number;
    message: string;
    typeOfNotarization: number;
    user: any;
    isPersonallyKnown: boolean;
    officialNotes: string;
    multipleSigner: boolean;
    official: string;
    userTimezone?: string;
    userPhone?: string;
    payerEmail?: string;
    appointmentType?: string;
    appointmentId?: number;
  },
  {
    rejectValue: any;
  }
>(
  "session/createSession",
  async (
    {
      documentsCount,
      emailCopies,
      name,
      officialPrivateNotes,
      scheduledAt,
      typeOfNotarization,
      user,
      message,
      isPersonallyKnown,
      multipleSigner,
      official,
      userTimezone,
      userPhone,
      officialNotes,
      payerEmail,
      appointmentType,
      appointmentId,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let body = {
        documentsCount,
        emailCopies,
        name,
        officialPrivateNotes,
        message,
        scheduledAt,
        typeOfNotarization,
        user,
        isPersonallyKnown,
        multipleSigner,
        official,
        userTimezone,
        userPhone,
        officialNotes,
        payerEmail,
        appointmentType,
        appointmentId,
      };
      if (official) {
        body = { ...body, official };
      }
      if (userTimezone) {
        body = { ...body, userTimezone };
      }
      if (userPhone) {
        body = { ...body, userPhone };
      }
      if (official) {
        body = { ...body, official };
      }
      if (officialNotes) {
        body = { ...body, officialNotes };
      }
      if (payerEmail) {
        body = { ...body, payerEmail };
      }
      if (message) {
        body = { ...body, message };
      }
      if (appointmentType) {
        body = { ...body, appointmentType };
      }
      if (appointmentId) {
        body = { ...body, appointmentId };
      }
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + "/sessions", {
        headers: {
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markSessionAsViewed = createAsyncThunk<
  any,
  {
    sessionId: string;

  },
  {
    rejectValue: any;
  }
>(
  "session/markSessionAsViewed",
  async (
    {
      sessionId
    },
    { rejectWithValue, dispatch }
  ) => {
    try {

      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + `/sessions/${sessionId}/mark-viewed`, {
        headers: {
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        method: "POST",
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sessionBatch = createAsyncThunk<
  any,
  {
    sessionIds: any[];
  },
  {
    rejectValue: any;
  }
>(
  "session/sessionBatch",
  async ({ sessionIds }, { rejectWithValue, dispatch }) => {
    try {
      const body = {
        sessionIds,
      };
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + "/sessions/batch", {
        headers: {
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        method: "POST",
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendDocumentRequest = createAsyncThunk<
  any,
  {
    email: string;
    instructions?: string;
    officialEmail?: string;
    appointmentId?: any;
  },
  {
    rejectValue: any;
  }
>(
  "session/sendDocumentRequest",
  async (
    { email, instructions, officialEmail, appointmentId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const body = {
        message: instructions,
        officialEmail,
        userEmail: email,
      };
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        API_URL + "/upload-document-request/" + appointmentId,
        {
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
          },
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocument = createAsyncThunk<
  any,
  { sessionId: string },
  {
    rejectValue: any;
  }
>(
  "session/getDocument",
  async ({ sessionId }, { rejectWithValue, dispatch }) => {
    try {
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        API_URL + `/documents?session-id=${sessionId}`,
        {
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDocument = createAsyncThunk<
  any,
  { documentId: string },
  {
    rejectValue: any;
  }
>(
  "session/deleteDocument",
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const firmId = getFromLocalStorage("firmId");
      const officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        `${API_URL}/documents/${documentId}/session`,
        {
          method: "DELETE",
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response)
      // const data = await response.json();

      // if (!response.ok) {
      //   return rejectWithValue(data);
      // }

      // return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRequest = createAsyncThunk<
  any,
  { requestId: string },
  {
    rejectValue: any;
  }
>(
  "session/deleteRequest",
  async ({ requestId }, { rejectWithValue }) => {
    try {
      const firmId = getFromLocalStorage("firmId");
      const officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        `${API_URL}/document-groups/${requestId}`,
        {
          method: "DELETE",
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response)
      // const data = await response.json();

      // if (!response.ok) {
      //   return rejectWithValue(data);
      // }

      // return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSession = createAsyncThunk<
  any,
  { sessionId: string },
  {
    rejectValue: any;
  }
>(
  "session/deleteSession",
  async ({ sessionId }, { rejectWithValue }) => {
    try {
      const firmId = getFromLocalStorage("firmId");
      const officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        `${API_URL}/sessions/${sessionId}`,
        {
          method: "DELETE",
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response)
      // const data = await response.json();

      // if (!response.ok) {
      //   return rejectWithValue(data);
      // }

      // return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getRecords = createAsyncThunk<
  any,
  { status: string, sessionId?: string },
  {
    rejectValue: any;
  }
>(
  "session/getRecords",
  async ({ status = "finished", sessionId }, { rejectWithValue, dispatch }) => {
    try {
      console.log("sessionId", sessionId)
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      let route = sessionId ? `/records?session-id=${sessionId}&status=${status}&with=screenShareUrl` : `/records?session-id=&status=${status}&with=screenShareUrl`
      const response = await fetch(
        API_URL + route,
        {
          headers: {
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficials = createAsyncThunk<
  any,
  {},
  {
    rejectValue: any;
  }
>("session/getOfficials", async (_, { rejectWithValue, dispatch }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    const response = await fetch(API_URL + `/officials`, {
      headers: {
        "X-Firm-Id": firmId,
        "X-Official-Id": officialId,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateRecord = createAsyncThunk<
  any,
  { recordId: string; updateData: Record<string, any> },
  { rejectValue: any }
>(
  "session/updateRecord",
  async ({ recordId, updateData }, { rejectWithValue }) => {
    try {
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + "/records/${recordId}", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        body: JSON.stringify(updateData),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadDoc = createAsyncThunk<
  any,
  { sessionId: string; fileName: string },
  { rejectValue: any }
>("session/uploadDoc", async ({ sessionId, fileName }, { rejectWithValue }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    const response = await fetch(
      API_URL +
      `/documents/upload-url?session-id=${sessionId}&filename=${fileName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
          // Authorization:
          //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmaXJtSWQiOiJiODZkNTllNC0zM2IwLTRjZjEtYTUyMC0zNTFiNzRlYTZiMGQiLCJ1c2VySWQiOiI2NThhZDRiNS03NjU3LTQzZGItOThhMC0xYzNlYWI2YWJjZDAifQ.mmET16nl8CmUrxaFgEjPzIj76OSy9WNtaVcvG19nksecn5tSEDt1wWGgQ2oWvCcUyji1ol7DBhm0kQ7Gihm4EQ",
        },
      }
    );
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkSessionStatus = createAsyncThunk<
  any,
  { sessionId: string; },
  { rejectValue: any }
>("session/uploadDoc", async ({ sessionId }, { rejectWithValue }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    const response = await fetch(
      `${API_URL}/sessions/status?session-id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
          // Authorization:
          //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmaXJtSWQiOiJiODZkNTllNC0zM2IwLTRjZjEtYTUyMC0zNTFiNzRlYTZiMGQiLCJ1c2VySWQiOiI2NThhZDRiNS03NjU3LTQzZGItOThhMC0xYzNlYWI2YWJjZDAifQ.mmET16nl8CmUrxaFgEjPzIj76OSy9WNtaVcvG19nksecn5tSEDt1wWGgQ2oWvCcUyji1ol7DBhm0kQ7Gihm4EQ",
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      return rejectWithValue(data);
    }
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
}
);

export const getRecordingUrl = createAsyncThunk<
  any,
  { sessionId: string; },
  { rejectValue: any }
>("session/getRecordingUrl", async ({ sessionId }, { rejectWithValue }) => {
  try {
    let firmId = getFromLocalStorage("firmId");
    let officialId = getFromLocalStorage("officialId");
    const response = await fetch(
      `${API_URL}/sessions/${sessionId}/recording`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
          // Authorization:
          //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmaXJtSWQiOiJiODZkNTllNC0zM2IwLTRjZjEtYTUyMC0zNTFiNzRlYTZiMGQiLCJ1c2VySWQiOiI2NThhZDRiNS03NjU3LTQzZGItOThhMC0xYzNlYWI2YWJjZDAifQ.mmET16nl8CmUrxaFgEjPzIj76OSy9WNtaVcvG19nksecn5tSEDt1wWGgQ2oWvCcUyji1ol7DBhm0kQ7Gihm4EQ",
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      return rejectWithValue(data);
    }
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
}
);

export const copyDocument = createAsyncThunk<
  any,
  { sessionId: string; documentId: number; sessionDocumentFilename: string },
  { rejectValue: any }
>(
  "session/copyDocument",
  async (
    { sessionId, documentId, sessionDocumentFilename },
    { rejectWithValue }
  ) => {
    try {
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      let body = {
        sessionId: sessionId,
        sessionDocumentFilename,
      };
      const response = await fetch(
        API_URL + `/documents/${documentId}/copy?sessionId=${sessionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
          },
          body: JSON.stringify(body),
        }
      );
      console.log("response", response);
      const data = await response.json();
      console.log("data", data);
      if (!response.ok) {
        return rejectWithValue(data);
      }

      let etag = getFileHash(data, response);

      return { data, etag };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getFileHash = (data: any, response: any) => {
  let etag;
  if (data && data?.CopyObjectResult && data?.CopyObjectResult?.ETag) {
    console.log();
    etag = data?.CopyObjectResult?.ETag;
  }

  if (!etag) {
    etag = response?.headers && response.headers.get("etag");
  }

  if (!etag) {
    console.error(
      "ETag header was not returned from S3 upload. Check your bucket configuration"
    );

    return null;
  }

  return etag.replace(/(^")|("$)/g, "");
};

export const uploadPDF = createAsyncThunk<
  any,
  { url: string; file: File },
  { rejectValue: any }
>("session/uploadPDF", async ({ url, file }, { rejectWithValue }) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/pdf",
      },
      body: file,
    });

    const data = await response.text();
    if (!response.ok) {
      return rejectWithValue(data);
    }

    let etag = getFileHash(data, response);

    return { data, etag };
  } catch (error: any) {
    return rejectWithValue(error.message || error);
  }
});

export const getDocumentWithFile = createAsyncThunk<
  any,
  { sessionId: string; fileName: string; name: string; hash: string },
  { rejectValue: any }
>(
  "session/getDocumentWithFile",
  async ({ sessionId, fileName, name, hash }, { rejectWithValue }) => {
    try {
      const body = {
        filename: fileName,
        hash,
        name,
        sessionId,
      };
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(
        API_URL + `/documents?filename=${fileName}&sessionId=${sessionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Firm-Id": firmId,
            "X-Official-Id": officialId,
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendNotification = createAsyncThunk<
  any,
  { status: string; notaryAppHost?: string; sessionId: string; note?: string },
  { rejectValue: any }
>(
  "session/sendNotification",
  async (
    {
      sessionId,
      notaryAppHost = "https://demo.onlinenotary.us",
      status,
      note = "",
    },
    { rejectWithValue }
  ) => {
    try {
      let body: {
        status: string;
        notaryAppHost: string;
        sessionId: string;
        note?: string;
      } = {
        status,
        notaryAppHost,
        sessionId,
      };
      if (status == CANCELLED_STATUS) {
        body = {
          ...body,
          note: note,
        };
      }
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + `/notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchEmail = createAsyncThunk<
  any,
  { email?: string },
  { rejectValue: any }
>("session/searchEmail", async ({ email }, { rejectWithValue }) => {
  try {
    const body = {
      email,
    };
    const response = await fetch(API_URL + `/officials/search-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (!response.ok) {
      return rejectWithValue(data);
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSessionDetail = createAsyncThunk<
  any,
  { details: any; sessionId: string },
  { rejectValue: any }
>(
  "session/updateSessionDetail",
  async ({ details, sessionId }, { rejectWithValue }) => {
    try {
      const body = {
        ...details,
      };
      let firmId = getFromLocalStorage("firmId");
      let officialId = getFromLocalStorage("officialId");
      const response = await fetch(API_URL + `/sessions/${sessionId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Firm-Id": firmId,
          "X-Official-Id": officialId,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const { } = sessionSlice.actions;

export const store = configureStore({
  reducer: {
    session: sessionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
