import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  left: 6%;
  height: 100vh; // Ensure it takes up the full height of the viewport
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
