const awsmobile = {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL, //'us-west-2_cuvVGAcg5',
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID, //'19b5eighuubc07e9atk3a48ago',
    oauth: {
      domain: 'demo-onlinenotary.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'openid', 'phone'],
      redirectSignIn: 'https://demo.onlinenotary.us/authenticated',  
      redirectSignOut: 'https://demo.onlinenotary.us/authenticated', 
      responseType: 'code'
    }
  };
  
  export default awsmobile;