import React from "react";
import styled from "styled-components";
import Select, { SingleValue, ActionMeta } from "react-select";
import { MdExpandMore } from "react-icons/md";

interface OptionType {
  value: string;
  label: string;
}

interface NotaryOwnerProps {
  value: OptionType;
  onChange: (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  options: OptionType[];
  styles?: any; // Optional minHeight prop
}

const getCustomStyles = (styles: any) => ({
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    width: "100%",
    border: "1px solid black",
    boxShadow: "none",
    minWidth: "170px",
    minHeight:"39px",
    cursor: "pointer",
    "&:hover": {
      borderColor: "black",
    },
    ...styles,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "black",
    borderColor: "black",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#D3D3D3"
      : state.isFocused
      ? "#f5f5f5"
      : "white",
    color: "black",
    cursor: "pointer",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: "4px",
    color: "black",
  }),
  indicatorSeparator: () => ({
    isDisabled: true,
  }),
  indicatorContainer: (base: any) => ({
    ...base,
    position: "absolute",
    right: "8px",
    top: "calc(50% - 8px)",
    cursor: "pointer",
  }),
  input: () => ({
    isDisabled: true,
  }),
});

const DropdownIndicator = () => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <MdExpandMore style={{ transform: "rotate(180deg)" }} />
    <MdExpandMore />
  </div>
);

const Dropdown: React.FC<NotaryOwnerProps> = ({
  onChange,
  value,
  options,
  styles,
}) => {
  return (
    <Select
      isSearchable={false}
      value={value}
      onChange={onChange}
      options={options}
      styles={getCustomStyles(styles)}
      components={{ DropdownIndicator }}
    />
  );
};

export default Dropdown;
