import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AcknowledgmentBox from "../notaryBook/AcknowledgmentBox";


interface AcknowledgmentDetails {
    signerName: string;
    documentName: string;
    documentType: string;
    fee: string;
    idExpiration: string;
    signerAddress: string;
    documentDate: string;
    idEvidence: string;
    notes: string;
    id: string;
    typeOfNotarizationText: string;
    notarizationDate: any;
}

interface AcknowledgmentModalProps {
    open: boolean;
    onClose: () => void;
    acknowledgments: any[];
}

const AcknowledgmentModal: React.FC<AcknowledgmentModalProps> = ({
    open,
    onClose,
    acknowledgments,
}) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, onClose]);

    if (!open) return null;

    return (
        <Overlay>
            <PopupContainer ref={popupRef}>
                <CloseButton onClick={onClose}>✕</CloseButton>
                <ScrollContainer>
                    {acknowledgments.map((ack, index) => (
                        <AcknowledgmentBox key={ack.id} acknowledge={ack} />
                    ))}
                </ScrollContainer>
            </PopupContainer>
        </Overlay>
    );
};

export default AcknowledgmentModal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const PopupContainer = styled.div`
  background: white;
  width: 600px;
  max-width: 90%;
  max-height: 60vh;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  padding: 16px;
`;

const ModalHeader = styled.h2`
  margin: 16px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;
