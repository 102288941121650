import { DefaultTheme } from "styled-components";

// Define the theme interface based on your theme structure
export const theme: DefaultTheme = {
  colors: {
    primary: "#2B70F4",
    secondary: "#0F459F",
    main: "#333344",
    normal: "#EFEFEF",
    success: "#28a745",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#17a2b8",
    light: "#E4EDFE",
    dark: "#343a40",
    grey: "#5E5D5D",
    greyText:"#343434",
    lightGrey: "#ECECEC",
    red: "#CC3434",
    primaryTextColor: "#254093",
    secondaryTextColor: "#223E92",
  },
  fonts: {
    base: "Helvetica, Arial, sans-serif",
  },
};

// Extend the DefaultTheme interface with your custom theme structure
declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      primary: string;
      secondary: string;
      main: string;
      normal: string;
      success: string;
      danger: string;
      warning: string;
      info: string;
      light: string;
      lightGrey: string;
      dark: string;
      greyText: string;
      grey: string;
      red: string;
      secondaryTextColor: string;
      primaryTextColor: string;
    };
    fonts: {
      base: string;
    };
  }
}
