import React, { useState } from "react";
import styled from "styled-components";
import { AppDispatch, sendDocumentRequest } from "../../store";
import { useDispatch } from "react-redux";
import { createUserLink } from "../../utils/sessionHelper";
import { notaryLink } from "../../utils/helper";
import { FaPaperPlane } from "react-icons/fa";
import { toast } from "react-toastify";

interface UploadLinkProps {
  onClose: () => void;
  session?: any;
}

const UploadLinkPopup: React.FC<UploadLinkProps> = ({ onClose, session }) => {
  const copyUrl = () => {
    if (session?.uploadSecurity) {
      const urlToCopy = createUserLink(notaryLink, session?.uploadSecurity);
      navigator.clipboard.writeText(urlToCopy).then(
        () => {
          toast.info("URL copied to clipboard!");
          onClose();
        },
        (err) => {
          toast.error("Could not copy text");
        }
      );
    }
  };

  return (
    <PopupOverlay onClick={onClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <PopupHeader>
          <FaPaperPlane color="#2B70F4" />{" "}
          <UploadText>Upload Request Sent</UploadText>
        </PopupHeader>
        <Label onClick={copyUrl}>Click here to copied</Label>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default UploadLinkPopup;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopupHeader = styled.h2`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  color: color: ${(props) => props.theme.colors.primary};
  align-items: center;
`;

const UploadText = styled.span`
  color: color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 60px;
  resize: none;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "close"
      ? `
        background-color: #ccc;
        color: #333;
      `
      : `
        background-color: #007bff;
        color: white;
      `}
`;
