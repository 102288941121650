import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmResetPassword } from "aws-amplify/auth";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg"; 
import background from "../assets/signin-bg.svg";

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: location.state?.email || "",
    code: "",
    newPassword: ""
  });

  const [errors, setErrors] = useState({
    code: "",
    newPassword: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); 
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      code: "",
      newPassword: ""
    };

    if (!formData.code) {
      newErrors.code = "Confirmation code is required";
      valid = false;
    }
    if (!formData.newPassword || formData.newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least 8 characters long";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    setMessage("");

    try {
      await confirmResetPassword({
        username: formData.email,
        newPassword: formData.newPassword,
        confirmationCode: formData.code
      });
      setMessage("Password has been reset successfully.");
      navigate('/login');
    } catch (error: any) {
      setErrors({ ...errors, code: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <ResetPasswordBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Reset Password</Title>
        <Form onSubmit={handleResetPassword} noValidate>
          <FieldBox>
            <Label>Confirmation Code</Label>
            <Input
              type="text"
              name="code"
              placeholder="Enter your confirmation code"
              value={formData.code}
              onChange={handleInputChange}
              required
            />
            {errors.code && <ErrorText>{errors.code}</ErrorText>}
          </FieldBox>
          <FieldBox>
            <Label>New Password</Label>
            <Input
              type="password"
              name="newPassword"
              placeholder="Enter your new password"
              value={formData.newPassword}
              onChange={handleInputChange}
              required
            />
            {errors.newPassword && <ErrorText>{errors.newPassword}</ErrorText>}
          </FieldBox>
          {message && <SuccessText>{message}</SuccessText>}
          <ResetButton type="submit" disabled={isSubmitting}>
            {isSubmitting ?  <LoadingSpinner/> : "Reset Password"}
          </ResetButton>
        </Form>
      </ResetPasswordBox>
    </PageContainer>
  );
};

export default ResetPasswordPage;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  width: 100%;
`;

const ResetPasswordBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
`;

const Form = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
`;

const FieldBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
`;

const ResetButton = styled.button`
  width: 70%;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

const SuccessText = styled.p`
  font-size: 12px;
  color: green;
  margin-top: 5px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;