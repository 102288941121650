import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg"; 
import { ReactComponent as HandPointer } from "../assets/hasnd_pointer.svg"; // Update the path to your hand pointer SVG

const RegistrationSubmittedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleFinish = () => {
    navigate("/");
  };

  return (
    <PageContainer>
      <ContentBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Account Registration Submitted!</Title>
        <StepsContainer>
            <InstructionText>To complete your account activation:</InstructionText>
          <Step>
            <IconContainer>
              <HandPointer />
            </IconContainer>
            <StepContent>
              <StepTitle>Step 01</StepTitle>
              <StepText>Click the <b> Activate Account </b> link in the email that you receive from the <b>eFile Manager</b>.</StepText>
            </StepContent>
          </Step>
          <Step>
            <IconContainer>
              <HandPointer />
            </IconContainer>
            <StepContent>
              <StepTitle>Step 02</StepTitle>
              <StepText>Enter the <b> Access Key </b> that <b> OnFile </b> will provide you in a separate email.</StepText>
            </StepContent>
          </Step>
        </StepsContainer>
        <FinishButton onClick={handleFinish}>Finish</FinishButton>
      </ContentBox>
    </PageContainer>
  );
};

export default RegistrationSubmittedPage;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white; // Adjust the background as needed
  width: 100%;
`;

const ContentBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  color: ${(props) => props.theme.colors.secondary};
  text-align: center;
  margin-bottom: 20px;
`;

const InstructionText = styled.p`
  font-size: 18px;
  color: black;
  margin-bottom: 30px;
  font-weight:bold;
`;

const StepsContainer = styled.div`
  width: 50%;
  margin-bottom: 30px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  flex-shrink: 0;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h2`
  font-size: 18px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 5px;
  margin-top: 5px;
`;

const StepText = styled.p`
  font-size: 16px;
  color: black;
`;

const FinishButton = styled.button`
    width: 50%;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.secondary};
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin: 20px auto 0;
`;
