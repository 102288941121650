import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import useAuthCheck from "../hooks/useAuthCheck";
import { ReactComponent as LogoIcon } from "../assets/Logo.svg";

const ProtectedLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthCheck(); 
  
  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/login"); 
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn === null) {
    return <Logo><LogoIcon /></Logo>; 
  }

  return (
    <>
      <Header />
      <MainContent>{children}</MainContent>
    </>
  );
};

export default ProtectedLayout;

const MainContent = styled.div`
  position: relative;
  display: flex;
  width: 94%;
  left: 6%;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw;
`;
