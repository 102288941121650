import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg";
import background from "../assets/signin-bg.svg";
import { signUp } from "aws-amplify/auth";
import { parseErrorMessage } from "../utils/helper";
import { useDispatch } from "react-redux";
import { AppDispatch, searchEmail } from "../store";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    confirmEmail: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    signup: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      email: "",
      confirmEmail: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      signup: "",
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }
    if (formData.email !== formData.confirmEmail) {
      newErrors.confirmEmail = "Emails do not match";
      valid = false;
    }
    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
      valid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      valid = false;
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsSignUpLoading(false);
      return;
    }

    setIsSignUpLoading(true);

    try {
      const searchEmailResponse = await dispatch(
        searchEmail({ email: formData.email })
      );
      if (searchEmailResponse.meta.requestStatus == "fulfilled") {
        const { email, password, firstName, lastName, middleName } = formData;
        const result = await signUp({
          username: email,
          password,
          options: {
            userAttributes: {
              name: `${firstName} ${lastName}`,
              middle_name: middleName,
            },
          },
        });

        navigate("/register-confirmation", { state: { email } });
      }
      if (searchEmailResponse.meta.requestStatus == "rejected") {
        throw new Error("You are not onboarded");
      }
    } catch (error: any) {
      setErrors({ ...errors, signup: parseErrorMessage(error.message) });
    } finally {
      setIsSignUpLoading(false);
    }
  };

  return (
    <PageContainer>
      <SignInBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Register</Title>
        <Form onSubmit={handleRegister} noValidate>
          <Row>
            <FieldBox>
              <Label>Email Address</Label>
              <Input
                type="email"
                name="email"
                placeholder="Enter here"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <ErrorText>{errors.email}</ErrorText>}
            </FieldBox>
            <FieldBox>
              <Label>Confirm Email Address</Label>
              <Input
                type="email"
                name="confirmEmail"
                placeholder="Enter here"
                value={formData.confirmEmail}
                onChange={handleInputChange}
                required
              />
              {errors.confirmEmail && (
                <ErrorText>{errors.confirmEmail}</ErrorText>
              )}
            </FieldBox>
          </Row>
          <Row>
            <FieldBox>
              <Label>First Name</Label>
              <Input
                type="text"
                name="firstName"
                placeholder="Enter here"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
              {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
            </FieldBox>
            <FieldBox>
              <Label>Middle Name</Label>
              <Input
                type="text"
                name="middleName"
                placeholder="Enter here"
                value={formData.middleName}
                onChange={handleInputChange}
              />
            </FieldBox>
            <FieldBox>
              <Label>Last Name</Label>
              <Input
                type="text"
                name="lastName"
                placeholder="Enter here"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
              {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}
            </FieldBox>
          </Row>
          <Row>
            <FieldBox>
              <Label>Create Password</Label>
              <Input
                type="password"
                name="password"
                placeholder="Enter here"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              {errors.password && <ErrorText>{errors.password}</ErrorText>}
            </FieldBox>
            <FieldBox>
              <Label>Confirm Password</Label>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Enter here"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
              {errors.confirmPassword && (
                <ErrorText>{errors.confirmPassword}</ErrorText>
              )}
            </FieldBox>
          </Row>
          <PasswordCriteriaText>
            Password must be at least eight characters with at least one lower
            case, at least one upper case, and at least one number or symbol.
          </PasswordCriteriaText>
          {errors.signup && <ErrorText>{errors.signup}</ErrorText>}
          <RegistrationButton type="submit">
            {isSignUpLoading ? <LoadingSpinner /> : "Submit Registration"}
          </RegistrationButton>
        </Form>
        <TextContainer>
          <Text>
            Already have an account?{" "}
            <SignInLink href="/login">Sign in</SignInLink>
          </Text>
        </TextContainer>
      </SignInBox>
    </PageContainer>
  );
};

export default RegisterPage;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  width: 100%;
`;

const SignInBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

const FieldBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box; /* Ensures padding is included in width */
`;

const PasswordCriteriaText = styled.p`
  font-size: 11px;
  color: #666;
  margin-top: -10px;
`;

const RegistrationButton = styled.button`
  width: 50%;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Text = styled.p`
  margin-bottom: 10px;
`;

const SignInLink = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
