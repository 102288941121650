import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface Document {
  filename: string;
  fileSealed?: string;
  file: string;
}

const handleDownloadDoc = async (documents: Document[], sessionId: string): Promise<void> => {
  if (!documents || documents.length === 0) {
    throw new Error('There are no documents to download');
  }

  const links = documents.map((doc) => {
    const parts = doc.filename.split('.');
    const ext = parts.pop() as string;
    const filename = parts.join('.');
    return {
      url: doc.fileSealed || doc.file,
      name: filename,
      ext: ext,
    };
  });

  try {
    const zipFileName = `${sessionId}_docs`;
    await remoteFilesToZip(links, zipFileName);
  } catch (error) {
    throw new Error('Download failed');
  }
};

interface FileLink {
  url: string;
  name: string;
  ext: string;
}

const remoteFilesToZip = async (fileLinks: FileLink[], zipName: string): Promise<void> => {
  const zip = new JSZip();
  const downloadProgress: { [key: string]: { loaded: number } } = {};

  const promises = fileLinks.map(async (doc) => {
    try {
      const response = await fetch(doc.url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${doc.name}`);
      }

      const blob = await response.arrayBuffer();
      zip.file(`${doc.name}.${doc.ext}`, blob);

      downloadProgress[doc.url] = { loaded: blob.byteLength };
    } catch (error) {
      throw new Error(`Failed to download ${doc.name}`);
      // console.error(`Failed to download ${doc.name}`, error);
    }
  });

  await Promise.all(promises);

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, `${zipName}.zip`);
};

export { handleDownloadDoc };
