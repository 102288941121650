import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { SingleValue, ActionMeta } from "react-select";
import Dropdown from "../home/Dropdown";
import { MdOutlineEdit } from "react-icons/md";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import {
  COMPLETED_SESSION_STATUSES,
  TIME_ZONES,
} from "../../utils/sessionHelper";
import {
  AppDispatch,
  RootState,
  deleteRequest,
  getDocument,
  getOfficials,
  sendNotification,
  updateRequestDetail,
  updateSessionDetail,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { ToastContentProps, toast } from "react-toastify";
import { checkFeatureAvailability } from "../../utils/permissions";
import RequestDocumentPopup from "../home/RequestDocumentPopup";
import UploadLinkPopup from "../home/UploadLinkPopup";
interface OptionType {
  value: string;
  label: string;
}

const labelMapping: { [key: string]: string } = {
  signerName: "Signer name",
  email: "Signer email",
  signerPhone: "Signer phone",
  signerTimezone: "Signer timezone",
  copyRecipient1: "Copy recipient 1",
  copyRecipient2: "Copy recipient 2",
  payerEmail: "Payer email",
  signers: "Signer(s)",
  sessionType: "Session type",
};

const SessionSummary: React.FC<{ session: any }> = ({ session }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedNotaryOwner, setSelectedNotaryOwner] = useState<any>();
  const [iframeUrl, setIframeUrl] = useState<string>(``);
  const [isUploadLinkPopupOpen, setIsUploadLinkPopupOpen] = useState(false);
  const [isRequestDocPopupVisible, setIsRequestDocPopupVisible] = useState(false);
  const [textareaValue, setTextareaValue] = useState(
    session?.officialPrivateNotes || ""
  );
  const [selectedSessionStage, setSelectedSessionStage] = useState<OptionType>({
    value: session?.stage,
    label: session?.stage,
  });
  const [selectedDocumentSeal, setSelectedDocumentSeal] = useState<number>(
    session?.documentsCount || 0
  );
  const [updatedNotaryOwner, setUpdatedNotaryOwner] = useState<any>(null);
  const [isNotaryOwnerEditable, setIsNotaryOwnerEditable] = useState(false);
  const [documentId, setDocumentId] = useState(session?.documentId);
  const [isEditable, setIsEditable] = useState(false);
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [updatedName, setUpdatedName] = useState(session?.user?.fullName || "");

  const {
    updatingSession: { loading: updateLoading },
  } = useSelector((state: RootState) => state.session);

  const [isDateEditable, setIsDateEditable] = useState(false);
  // const [selectedDate, setSelectedDate] = useState<Date>(
  //   session?.date ? new Date(session.date) : new Date("July 5, 2024")
  // );
  const [isAssignmentAvailable, setIsAssignmentAvailable] = useState(false);
  const initialDate = new Date(session?.requestedDatetime);
  const [officials, setOfficials] = useState<any[]>([]);
  const [sessionDate, setSessionDate] = useState<Date>(initialDate);
  const [tempDate, setTempDate] = useState<Date>(initialDate);
  const [isDeleteRequestPopupVisible, setIsDeleteRequestPopupVisible] = useState(false);
  const [isDeleteRequestLoading, setIsDeleteRequestLoading] = useState(false);
  const [isNotaryOfficialSaveLoading, setIsNotaryOfficialSaveLoading] = useState(false);
  const [isSessionDetailsEditable, setIsSessionDetailsEditable] =
    useState(false);
  const [updatedStage, setUpdatedStage] = useState<OptionType | null>(null);

  const [signerDetail, setSignerDetail] = useState({
    email: session?.userEmail || "",
    payerEmail: session?.payerEmail || "",
    copyRecipient1: session?.cc,
    copyRecipient2: session?.copy2,
    sessionType: session?.appointmentType,
    signers: session?.multipleSigner == "yes" ? "Multiple" : "Single",
    signerPhone: session?.userPhone || "",
    signerTimezone: session?.userTimezone || "",
  });
  const [isSignerDetailEditable, setIsSignerDetailEditable] = useState(false);
  const [isNotaryAdmin, setIsNotaryAdmin] = useState(false);

  const datePickerRef = useRef<HTMLDivElement>(null);
  const personaClientRef = useRef<any>(null);

  const handleSignerDetailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSignerDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignerDetailEditClick = () => {
    setIsSignerDetailEditable(true);
  };

  const handleSignerDetailSave = async () => {
    let updatingSignerDetail = {};
    if (signerDetail.copyRecipient1 && signerDetail.copyRecipient2) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient1, signerDetail.copyRecipient2],
      };
    } else if (signerDetail.copyRecipient1) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient1],
      };
    } else if (signerDetail.copyRecipient2) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        emailCopies: [signerDetail.copyRecipient2],
      };
    }
    if (signerDetail.payerEmail) {
      updatingSignerDetail = {
        ...updatingSignerDetail,
        payerEmail: signerDetail.payerEmail,
      };
    }

    await handleUpdateDetails({ ...updatingSignerDetail }, "signerDetail");
    setIsSignerDetailEditable(false);
  };

  const handleChangeSessionStage = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      if (newValue && newValue.value !== session?.stage) {
        setSelectedSessionStage(newValue);
        setUpdatedStage(newValue);
        setIsSessionDetailsEditable(true);
      } else {
        setIsSessionDetailsEditable(false);
      }
    },
    [session]
  );

  const handleSignerDetailCancel = () => {
    setIsSignerDetailEditable(false);
    setSignerDetail({
      email: session?.userEmail || "",
      payerEmail: session?.payerEmail || "",
      copyRecipient1: session?.cc,
      copyRecipient2: session?.copy2,
      sessionType: session?.appointmentType || "",
      signers: session?.multipleSigner == "yes" ? "Multiple" : "Single",
      signerPhone: session?.userPhone || "",
      signerTimezone: session?.userTimezone || "",
    });
  };

  const handleChangeNotaryOwner = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {

      const selectedOfficial = officials.find(
        (official) => official.name === newValue?.value
      );

      if (
        selectedOfficial &&
        selectedOfficial.name !== session?.official?.name
      ) {
        setSelectedNotaryOwner({
          value: selectedOfficial.name,
          label: selectedOfficial.name,
        });
        if (selectedOfficial?.commissionExpirationDate) {
          const date = new Date(
            selectedOfficial?.commissionExpirationDate?._seconds * 1000
          );
          const formattedDate = date.toString();
          selectedOfficial["commissionExpirationDate"] = formattedDate;
        }
        setUpdatedNotaryOwner(selectedOfficial);
        setIsNotaryOwnerEditable(true);
      } else {
        setIsNotaryOwnerEditable(false);
      }
    },
    [officials, session]
  );

  const handleNotaryOwnerSave = async () => {
    try {
      setIsNotaryOfficialSaveLoading(true);
      if (updatedNotaryOwner) {
        console.log("session=== save", session)
        let previousState = {
          assignedToUserId: session?.assignedToUserId,
          assignedToFirmId: session?.assignedToFirmId,
        };
        console.log("previousState", previousState)
        console.log("updatedNotaryOwner===>", updatedNotaryOwner)
        const updateSessionDetailResponse = await dispatch(
          updateRequestDetail({
            details: {
              assignedToUserId: updatedNotaryOwner?.uid,
              assignedToFirmId: updatedNotaryOwner?.firmId,
            },
            requestId: session?.id,
          })
        );
        if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
          setIsNotaryOwnerEditable(false);
          setUpdatedNotaryOwner(null);
          toast.success(
            ({ closeToast }: ToastContentProps) => (
              <div>
                <span>Details have been updated</span>
                <button
                  onClick={() => {
                    handleUndo(previousState);
                    closeToast();
                  }}
                  style={{
                    marginLeft: "10px",
                    background: "none",
                    border: "none",
                    color: "#007bff",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Undo
                </button>
              </div>
            ),
            {
              autoClose: 5000,
            }
          );
        }
        if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
          setIsNotaryOfficialSaveLoading(false);
          toast.error("Something went wrong", {
            autoClose: 5000,
          });
        }
      }
    }
    catch (err) {
      setIsNotaryOfficialSaveLoading(false);
      console.log("err===>", err)
    }
  };

  const handleNotaryOwnerCancel = () => {
    setIsNotaryOwnerEditable(false);
    setUpdatedNotaryOwner(null);
    setSelectedNotaryOwner({
      value: session?.official?.name,
      label: session?.official?.name,
    });
  };

  const generateSecurityCredentials = function (securityParams: any) {
    if (!securityParams) {
      console.error("No security credentials provided!");

      return false;
    }

    return Object.keys(securityParams)
      .map(function (param) {
        return param + "=" + securityParams[param];
      })
      .join("&");
  };

  const createOfficialLink = (notaryAppLink: any, security: any) => {
    return (
      notaryAppLink + "?" + generateSecurityCredentials(security) + "#/official"
    );
  };

  const fetchDocument = async ({ id }: any) => {
    try {
      const getDocumentResponse = await dispatch(
        getDocument({ sessionId: id })
      );

      if (getDocumentResponse.meta.requestStatus == API_STATUS.fulfilled) {
        const documentIdTemp = getDocumentResponse?.payload[0]?.document_id;
        setDocumentId(documentIdTemp);
      }
      if (getDocumentResponse.meta.requestStatus == "rejected") {
        toast.error("Something went wrong", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("error in get session api", error);
    }
  };

  const handleDateEditClick = () => {
    // if (session) setIsDateEditable(true);
  };

  const handleDateChange = (
    date: Date | Date[] | null,
    event?: React.SyntheticEvent<any> | undefined
  ) => {
    if (date) {
      const selectedDate = Array.isArray(date) ? date[0] : date;
      setTempDate(selectedDate);
    }
  };

  const handleSaveDate = async () => {
    await handleUpdateDetails({ requestedDatetime: tempDate }, "scheduling");
    setSessionDate(tempDate);
  };

  const handleCancelDateEdit = () => {
    setTempDate(sessionDate);
    setIsDateEditable(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsDateEditable(false);
    }
  };

  useEffect(() => {
    if (session?.id) {
      // fetchDocument(session);
      (async () => {
        const isAvailable: any = await checkFeatureAvailability();
        setIsAssignmentAvailable(isAvailable);
        const getOfficialsResponse = await dispatch(getOfficials({}));
        if (getOfficialsResponse.meta.requestStatus === "fulfilled") {
          setOfficials([...getOfficialsResponse.payload]);
          let tempSelectedOfficial = [...getOfficialsResponse.payload].find(function (official) {
            return session.assignedToUserId === official.uid;
          });
          console.log("tempSelectedOfficial", tempSelectedOfficial)
          setSelectedNotaryOwner({
            label: tempSelectedOfficial?.name,
            value: tempSelectedOfficial?.name,
          });
        }
        if (getOfficialsResponse.meta.requestStatus == "rejected") {
          toast.error("Something went wrong", {
            autoClose: 5000,
          });
        }
      })();
    }
  }, [session?.id]);

  useEffect(() => {
    setIsDateEditable(false);
    if (session) {
      // fetchDocument(session);
      setTextareaValue(session?.officialPrivateNotes || "");
      setUpdatedName(session?.user?.fullName || "");
      setSignerDetail({
        ...signerDetail,
        payerEmail: session?.payerEmail || "",
        email: session?.userEmail || "",
        copyRecipient1: session?.cc,
        copyRecipient2: session?.copy2,
        sessionType: session?.appointmentType || "",
        signers: session?.multipleSigner == "yes" ? "Multiple" : "Single",
        signerPhone: session?.userPhone || "",
        signerTimezone: session?.userTimezone || "",
      });
      const initialDate = new Date(session?.requestedDatetime);
      setSessionDate(initialDate);
      setIframeUrl(`https://demo.onlinenotary.us/#/document/${session?.documents[0]?.documentId}`)
      setSelectedSessionStage({
        value: session?.stage,
        label: session?.stage,
      });

      const isNotaryAdminTemp = session.official?.roles
        ? session?.official?.roles.some((item: string) =>
          ["notaryAdmin", "scheduler", "remoteSignSessionCreator"].includes(
            item
          )
        )
        : false;
      setIsNotaryAdmin(isNotaryAdminTemp);
      if (session?.officialName) {
        // officialsTemp = officialsTemp?.find(
        //   (item: any) => item?.name == session?.officialName
        // );
        // setSelectedNotaryOwner({
        //   value: session?.officialName,
        //   label: session?.officialName,
        // });
        // setOfficials([...getOfficialsResponse.payload]);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   assignedToNotary: session?.officialName,
        // }));
      }
    }
  }, [session]);
  console.log("iframUrl", iframeUrl);
  useEffect(() => {
    if (isDateEditable) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDateEditable]);

  const onJoin = (session: any, path?: any) => {
    if (!session.security) {
      console.error("No security credentials provided!");
      return false;
    }
    const notaryLink = "https://demo.onlinenotary.us";
    var link = createOfficialLink(notaryLink, session.security);
    if (path) {
      link += path;
    }

    window.open(link, "newwindow", "width=1200,height=1100");
  };

  const handleCreateSession = () => {
    navigate("/createSession", { state: { session, isSessionInsideRequest: true } });
  };

  const handleUndo = async (previousData: any) => {
    const updateSessionDetailResponse = await dispatch(
      updateSessionDetail({
        details: {
          ...previousData,
        },
        sessionId: session?.id,
      })
    );
    if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
      toast.success("Changes have been undone", {
        autoClose: 5000,
      });
    }
    if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
      toast.error("Something went wrong", {
        autoClose: 5000,
      });
    }
  };

  const handleDocument = async (documentId: string) => {
    setIframeUrl(`https://demo.onlinenotary.us/#/document/${documentId}`);
    setDocumentId(documentId);
  };

  const handleUpdateDetails = async (data: any, type: string) => {
    let previousState = {};
    if (type == "sessionDetail") {
      previousState = {
        stage: session?.stage,
        documentsCount: session?.documentsCount,
      };
    }
    if (type == "notes") {
      previousState = {
        officialPrivateNotes: session?.officialPrivateNotes,
      };
    }
    if (type == "signerDetail") {
      previousState = {
        payerEmail: session?.payerEmail || "",
        emailCopies: [...session?.emailCopies],
      };
    }
    if (type == "notaryOwner") {
      previousState = {
        official: {
          ...session?.official,
        },
      };
    }
    if (type == "cancel") {
      previousState = {
        stage: session?.stage,
      };
    }
    if (type == "restart") {
      previousState = {
        stage: session?.stage,
        sessionId: session?.id,
        idImageFront: session?.idImageFront,
        selfieImage: session?.selfieImage,
        idCaptureError: session?.idCaptureError,
        latestSubStage: session?.latestSubStage,
        eventLog: session?.eventLog,
        idParts: session?.idParts,
        documentType: session?.documentType,
      };
    }
    if (type == "scheduling") {
      previousState = {
        scheduledAt: session?.scheduledAt,
      };
    }
    if (type == "settings") {
      previousState = {
        settings: {
          isMeetingDisabled: session?.isMeetingDisabled,
        },
      };
    }
    const updateSessionDetailResponse = await dispatch(
      updateSessionDetail({
        details: {
          ...data,
        },
        sessionId: session?.id,
      })
    );
    if (updateSessionDetailResponse.meta.requestStatus == "fulfilled") {
      setIsEditable(false);
      setIsNameEditable(false);
      setIsDateEditable(false);
      setIsSessionDetailsEditable(false);
      toast.success(
        ({ closeToast }: ToastContentProps) => (
          <div>
            <span>Details have been updated</span>
            <button
              onClick={() => {
                handleUndo(previousState);
                closeToast();
              }}
              style={{
                marginLeft: "10px",
                background: "none",
                border: "none",
                color: "#007bff",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Undo
            </button>
          </div>
        ),
        {
          autoClose: 5000,
        }
      );
    }
    if (updateSessionDetailResponse.meta.requestStatus == "rejected") {
      toast.error("Something went wrong", {
        autoClose: 5000,
      });
    }
  };

  const handleRequestDoc = async () => {
    setIsUploadLinkPopupOpen(false);
    setIsRequestDocPopupVisible(true);
  };

  const handleDownloadDoc = async (
    url: string,
    filename: string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation(); // Prevents any unwanted event propagation


  };

  const handleCopyAppointmentId = () => {
    if (session?.appointmentId) {
      navigator.clipboard.writeText(session.appointmentId).then(() => {
        toast.info("Appointment ID copied to clipboard!", {
          autoClose: 3000,
        });
      }).catch(() => {
        toast.error("Failed to copy Appointment ID.", {
          autoClose: 3000,
        });
      });
    }
  };

  const handlePreviewDocument = (documentUrl: string) => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    } else {
      toast.error("Document URL is invalid or missing.", {
        autoClose: 5000,
      });
    }
  };

  const closeRequestDocPopup = () => {
    setIsRequestDocPopupVisible(false);
  };

  const handleUploadLinkPopupClose = () => {
    setIsUploadLinkPopupOpen(false);
    setIsRequestDocPopupVisible(false);
  };

  const handleUploadLinkPopuOpen = () => {
    setIsUploadLinkPopupOpen(true);
  };

  const deletingRequest = async () => {
    try {

      setIsDeleteRequestLoading(true);
      const deleteRequestResponse = await dispatch(
        deleteRequest({ requestId: session?.id })
      );
      if (deleteRequestResponse.meta.requestStatus == API_STATUS.fulfilled) {
        toast.success("Request deleted successfully!", {
          autoClose: 5000,
        });

        navigate("/sessionRequests");
        setIsDeleteRequestPopupVisible(false);
      }
      setIsDeleteRequestLoading(false)
    }
    catch (err: any) {
      setIsDeleteRequestLoading(false)
      toast.error("Error while deleting the request", {
        autoClose: 5000,
      });
    }
  }
  console.log("selectedNotaryOwner==>", selectedNotaryOwner)

  const closeDeleteRequestPopup = () => {
    setIsDeleteRequestPopupVisible(false);
  };

  const openDeleteRequestPopup = () => {
    setIsDeleteRequestPopupVisible(true);
  };

  return (
    <Container>
      <NotaryActionBox>
        <FirstColumn>
          <NameContainer>
            {/* {isNameEditable ? (
              <NameInput
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            ) : (
              <Name>{session?.user?.fullName}</Name>
            )} */}
            <Name>{session?.name}</Name>
            <DeleteIcon onClick={openDeleteRequestPopup} style={{ cursor: "pointer" }} />
            {/* {!isNameEditable && <EditIcon onClick={isNameEditable ? handleCancelClick : handleNameEditClick} />} */}
          </NameContainer>
          {/* {isNameEditable && (
            <ButtonGroupName>
              <Button onClick={handleCancelClick} variant="cancel">Cancel</Button>
              <Button onClick={() => handleUpdateDetails({ name: updatedName })} variant="save">
                {updateLoading ? <LoadingSpinner /> : "Save"}
              </Button>
            </ButtonGroupName>
          )} */}
        </FirstColumn>
        <SecondColumn>
          <IntroDetail>
            {/* <StyledDate>
              <SlCalender style={{ marginRight: "8px" }} />
              Friday July 5, 2024
            </StyledDate> */}
            <DateContainer>
              <StyledDate onClick={handleDateEditClick}>
                {/* <SlCalender style={{ marginRight: "8px" }} /> */}
                {moment(sessionDate).format("MMMM D, YYYY h:mm A")}
              </StyledDate>
              {isDateEditable && (
                <DatePickerWrapper ref={datePickerRef}>
                  <DatePicker
                    selected={tempDate}
                    onChange={handleDateChange}
                    inline
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Time"
                  />
                  <ButtonGroupDate>
                    <Button onClick={handleCancelDateEdit} variant="cancel">
                      Cancel
                    </Button>
                    <Button onClick={handleSaveDate} variant="save">
                      {updateLoading ? <LoadingSpinner /> : "Save"}
                    </Button>
                  </ButtonGroupDate>
                </DatePickerWrapper>
              )}
            </DateContainer>

            <AppoitmentId onClick={handleCopyAppointmentId}>
              {/* <HiOutlineClipboardDocumentList
                style={{ marginRight: "4px", fontSize: "20px" }}
              /> */}
              <AppoitmentIdTitle>Appointment ID </AppoitmentIdTitle>
              {session?.appointmentId}
            </AppoitmentId>
          </IntroDetail>
          <NotaryOwnerBox>
            {!isAssignmentAvailable && selectedNotaryOwner?.name ? (
              <>
                <NotaryTitle>Notary</NotaryTitle>
                <span>{selectedNotaryOwner?.label}</span>
              </>
            ) : (
              <>
                <NotaryTitle>Notary</NotaryTitle>
                <Dropdown
                  onChange={handleChangeNotaryOwner}
                  value={selectedNotaryOwner}
                  options={officials.map((item) => {
                    return { value: item.name, label: item.name };
                  })}
                />
              </>
            )}
          </NotaryOwnerBox>
          <CreateSessionButton onClick={handleCreateSession}>
            Create Session
          </CreateSessionButton>
        </SecondColumn>
        {isNotaryOwnerEditable && (
          <ButtonGroup>
            <Button onClick={handleNotaryOwnerCancel} variant="cancel">
              Cancel
            </Button>
            <Button onClick={handleNotaryOwnerSave} variant="save">
              {isNotaryOfficialSaveLoading ? <LoadingSpinner /> : "Save"}
            </Button>
          </ButtonGroup>
        )}
      </NotaryActionBox>
      <SignerDetailBox>
        <SignerTitle>
          Signer Detail{" "}
          {/* {!isSignerDetailEditable && (
            <EditIcon onClick={handleSignerDetailEditClick} />
          )} */}
        </SignerTitle>
        <Columns>
          {Object.keys(signerDetail).map((key, index) => (
            <Column key={index}>
              <ColumnName>
                {labelMapping[key] || key.split(/(?=[A-Z])/).join(" ")}
              </ColumnName>
              {isSignerDetailEditable ? (
                key === "signerTimezone" ? (
                  <StyledSelect
                    name={key}
                    value={(signerDetail as any)[key]}
                    onChange={handleSignerDetailChange}
                    required
                  >
                    <option value="">Select Time Zone</option>
                    {TIME_ZONES.map((zone) => (
                      <option key={zone.name} value={zone.value}>
                        {zone.name}
                      </option>
                    ))}
                  </StyledSelect>
                ) : (
                  <ColumnValue
                    name={key}
                    value={(signerDetail as any)[key]}
                    onChange={handleSignerDetailChange}
                    isEditable={
                      key == "copyRecipient1" ||
                        key == "copyRecipient2" ||
                        key == "payerEmail"
                        ? isSignerDetailEditable
                        : false
                    }
                  />
                )
              ) : (
                <ColumnValue
                  isEditable={
                    key == "copyRecipient1" ||
                      key == "copyRecipient2" ||
                      key == "payerEmail"
                      ? isSignerDetailEditable
                      : false
                  }
                  readOnly
                  value={(signerDetail as any)[key]}
                />
              )}
            </Column>
          ))}
        </Columns>
        {isSignerDetailEditable && (
          <ButtonGroupSignerDetail>
            <Button onClick={handleSignerDetailCancel} variant="cancel">
              Cancel
            </Button>
            <Button onClick={handleSignerDetailSave} variant="save">
              {updateLoading ? <LoadingSpinner /> : "Save"}
            </Button>
          </ButtonGroupSignerDetail>
        )}
      </SignerDetailBox>

      <TabBox>
        <TabContent>
          <IframeHeader>
            {session?.documents.map((document: any) => {
              return (
                <IframeHeaderBox
                  onClick={() => handleDocument(document?.documentId)}
                >
                  <DocName>{document?.filename}</DocName>
                  <IconsBox>
                    {/* <NewDocumentIcon onClick={() => handlePreviewDocument(document?.file)} />
                    <RequestIcon onClick={handleRequestDoc} /> */}
                    <DownloadIcon
                      onClick={(event) =>
                        handleDownloadDoc(
                          document?.file,
                          document?.filename,
                          event
                        )
                      }
                    />
                  </IconsBox>
                </IframeHeaderBox>
              );
            })}
          </IframeHeader>

          <StyledIframe
            ng-show="showCanvas"
            ng-src={iframeUrl}
            aria-hidden="false"
            className=""
            src={iframeUrl}
          ></StyledIframe>

        </TabContent>
      </TabBox>
      {isRequestDocPopupVisible && (
        <RequestDocumentPopup
          onClose={closeRequestDocPopup}
          session={session}
          onSuccess={handleUploadLinkPopuOpen}
        />
      )}
      {isUploadLinkPopupOpen && (
        <UploadLinkPopup
          onClose={handleUploadLinkPopupClose}
          session={session}
        />
      )}
      {isDeleteRequestPopupVisible && (
        <PopupOverlay onClick={closeDeleteRequestPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupHeader>Are you sure you want to delete this request?</PopupHeader>
            <ButtonGroup>
              <DynamicButton onClick={() => deletingRequest()} variant="save">
                {isDeleteRequestLoading ? <LoadingSpinner /> : "Yes"}
              </DynamicButton>
              <DynamicButton onClick={closeDeleteRequestPopup}>Cancel</DynamicButton>
            </ButtonGroup>
          </PopupContainer>
        </PopupOverlay>
      )}
    </Container>
  );
};

export default SessionSummary;

const Container = styled.div`
  flex: 73;
  height: fit-content;
  background: #f9f9f9;
  padding: 20px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex: none;
    width: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 120vh;
  border: none;
  overflow: hidden;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 25px;
  border-radius: 10px;
  gap: 40px;
  overflow: hidden; /* Hides scrollbars */
`;

const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 40px;
  overflow: hidden; /* Hides scrollbars */
`;

const Select = styled.select`
  border: 1px solid black;
  padding: 8px;
  margin-top: 8px;
  background: transparent;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%20011.708%200L8%209.293l5.646-5.647a.5.5%200%20011.708.708l-6%206a.5.5%200%2001-.708%200l-6-6a.5.5%200%20010-.708z%22%20clip-rule%3D%22evenodd%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
`;

const NotaryOwnerBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
`;

const NotaryTitle = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
`;

const NotaryActionBox = styled.div`
  background: white;
  padding: 25px 30px;
  padding-bottom: 15px;
  // overflow: hidden; /* Hides scrollbars */
  border-radius: 10px;
`;

const FirstColumn = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: ${(props) => `2px solid ${props.theme.colors.normal}`}; 
`;

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

const NameInput = styled.input`
  font-size: 22px;
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  width: 100%;
`;

const Name = styled.span`
  font-size: 35px;
  color: black;
  font-weight: 800;
  width: 100%;
`;

const SecondColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18px;
  border-radius: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px 30px;
  }
`;

const IntroDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StyledDate = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;
const AppoitmentId = styled.span`
  display: flex;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
`;
const AppoitmentIdTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
`;

const SignerDetailBox = styled.div`
  padding: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 18px;
  border-radius: 10px;
  border-left: 7px solid #c1d3f5;
`;

const SignerTitle = styled.h3`
  text-align: left;
  margin-bottom: 35px;
  font-size: 20px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 16px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
  gap: 7px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 18px;
`;

const ColumnName = styled.div`
  color: grey;
  font-size: 14px;
`;

const ColumnValue = styled.input<{ isEditable: boolean }>`
  color: black;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: scroll;
  background-color: #f9f9f9;
  cursor: ${(props) => (props.isEditable ? "text" : "default")};
  scrollbar-width: none; /* Hides scrollbars */
  &::-webkit-scrollbar {
    display: none; /* Hides scrollbars */
  }
`;

const RowName = styled.span`
  color: black;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: not-allowed;
`;

const Badge = styled.span`
  background-color: #c1f5d0;
  border-radius: 8px;
  padding: 6px 18px;
  height: 16px;
  font-size: 12px;
  white-space: nowrap;
  color: #249c46;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-contents: center;
  text-transform: uppercase;
`;

const TabBox = styled.div`
  background-color: white;
  margin-top: 18px;
  border-radius: 10px;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 20px;
  border-bottom: 2px solid #ccc;
  border-bottom-color: #ccc;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 15px 55px;
  color: grey;
  text-align: center;
  cursor: pointer;
  border-bottom: ${(props) => (props.isActive ? "2px solid #007bff" : "none")};
  border-bottom-color: ${(props) => (props.isActive ? "#007bff" : "none")};
`;

const TabContent = styled.div`
  padding: 20px;
  background-color: white;
  margin-top: 25px;
  overflow: hidden; /* Hides scrollbars */
  border-radius: 10px;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupHeader = styled.h2`
  text-align: center;
`;

const PopupDescription = styled.h4`
  text-align: left;
  margin-right: auto;
  font-weight: 400;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ButtonCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const ButtonGroupName = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ButtonGroupSignerDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 12px;
`;

const RestartButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const DisableAudioVideoButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px;
`;

const InputLabel = styled.label<{ isFocused: boolean }>`
  position: absolute;
  left: ${(props) => (props.isFocused ? "12px" : "24px")};
  top: ${(props) => (props.isFocused ? "-10px" : "20px")};
  color: ${(props) => (props.isFocused ? "#007bff" : "grey")};
  font-size: ${(props) => (props.isFocused ? "12px" : "16px")};
  transition: all 0.3s ease;
  pointer-events: none;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding-left: 12px;
  box-sizing: border-box;

  &:focus + ${InputLabel} {
    top: -10px;
    font-size: 12px;
    color: #007bff;
  }
`;

const NotesActionBox = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 25px;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden; /* Hides scrollbars */
`;

const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const NotesTitle = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
  font-size: 20px;
`;

const EditText = styled.span`
  color: ${(props) => props.theme.colors.grey};
  cursor: pointer;
  font-size: 10px;
  text-align: right;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const UploadIdButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: fit-content;
  background: #e7ecf5;
  color: #0f459f;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const UploadedImgContainer = styled.div`
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

const DateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  // top: 40px;
  // left: 0;

  .react-datepicker {
    display: flex;
  }
`;

const ButtonGroupDate = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const CustomDatePicker = styled(DatePicker)`
  .react-datepicker__time-container {
    width: 100px; /* Adjust width */
    margin: 0;
    padding: 0;
    background: white;
  }

  .react-datepicker__time-list {
    padding: 0;
    width: 100%; /* Make sure the time list takes full width */
  }

  .react-datepicker__time-list-item {
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
    background: white;
  }

  .react-datepicker__time-list-item--selected {
    background: #007bff;
    color: white;
  }
`;

const CreateSessionButton = styled.button`
  padding: 10px;
  background-color: #06B050;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 20%;
`;

const StyledSelect = styled.select`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  background: url("path/to/your/custom/dropdown-icon.png") no-repeat right;
  background-position-x: calc(100% - 10px);
`;

const IconsBox = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  gap: 18px;
`;

const IframeHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden; /* Hides scrollbars */
  padding-top: 7px;
  padding-bottom: 7px;
`;

const IframeHeader = styled.div``;

const DocName = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;


const DynamicButton = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;