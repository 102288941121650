import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import ScheduleBox from "./ScheduleBox";
import { useDispatch } from "react-redux";
import { AppDispatch, getNotarySessions } from "../../store";
import { API_STATUS } from "../../utils";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CreateSessionPlusIcon } from "../../assets/plus-sm-green.svg";

const SessionTimeline: React.FC<{
  onSessionSelect: (session: any) => void;
  activeSessionId?: string;
}> = ({ onSessionSelect, activeSessionId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isMounted = useRef(true);
  const [isSessionLoading, setIsSessionLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [sessions, setSessions] = useState<any>([]);

  const handleBoxClick = (index: number) => {
    setSelectedBox(index);
    if (sessions && sessions[index]) {
      onSessionSelect(sessions[index]);
    }
  };

  const fetchSessions = useCallback(
    debounce(async (showLoading = true) => {
      try {
        if (showLoading && isFirstLoad) {
          setIsSessionLoading(true);
        }

        const fetchBothSessions = async () => {
          const notarySessions: any = await dispatch(getNotarySessions({}));
          if (notarySessions.meta.requestStatus === API_STATUS.fulfilled) {
            let filteredNotarySessions = notarySessions.payload?.items?.filter(
              (item: any) => item?.isConfirmed === "1"
            );
            setSessions([...filteredNotarySessions]);

            const allSessions = [...filteredNotarySessions];
            if (activeSessionId) {
              const activeSession = allSessions.find(
                (session) => session.id.toString() === activeSessionId
              );
              if (activeSession) {
                onSessionSelect(activeSession);
                setSelectedBox(allSessions.indexOf(activeSession));
              }
            } else {
              const activeSession = allSessions[0];
              if (activeSession) {
                onSessionSelect(activeSession);
                setSelectedBox(allSessions.indexOf(activeSession));
              }
            }
          }
        };

        await fetchBothSessions();
      } catch (error) {
        console.error("error in get session api", error);
      } finally {
        if (isMounted.current && showLoading) {
          setIsSessionLoading(false);
          if (isFirstLoad) {
            setIsFirstLoad(false);
          }
        }
      }
    }, 300),
    [dispatch, activeSessionId, onSessionSelect, isFirstLoad]
  );

  useEffect(() => {
    isMounted.current = true;
    fetchSessions();
    return () => {
      isMounted.current = false;
      fetchSessions.cancel();
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => fetchSessions(false), 2000);

    return () => clearInterval(intervalId);
  }, [fetchSessions]);

  const handleCreateSession = () => {
    navigate("/createSession", { state: { isSessionRequest: true } });
  };

  return (
    <SessionInfoBox>
      <CreateSessionButton onClick={handleCreateSession}>
        Create Request <CreateSessionPlusIcon />
      </CreateSessionButton>
      <ScheduleBoxContainer isLoading={isSessionLoading}>
        {isSessionLoading && isFirstLoad ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : sessions.length === 0 ? (
          <NoItemsFound>No items found</NoItemsFound>
        ) : (
          sessions?.map((item: any, index: number) => (
            <ScheduleBox
              key={index}
              isSelected={selectedBox === index}
              onClick={() => handleBoxClick(index)}
              item={item}
            />
          ))
        )}
      </ScheduleBoxContainer>
    </SessionInfoBox>
  );
};

export default SessionTimeline;

const NoItemsFound = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #888;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
`;

const ScheduleBoxContainer = styled.div<{ isLoading: boolean }>`
  padding: 8px;
  display: ${(props) => (props.isLoading ? "flex" : "block")};
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  flex: 1;
  cursor: pointer;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SessionInfoBox = styled.div`
  width: 98%;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CreateSessionButton = styled.button`
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px;
  color: #64BD8B;
  background-color: white;
  border: 2px solid #64BD8B;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px auto 0;
`;
