import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import AcknowledgmentBox from "../components/notaryBook/AcknowledgmentBox";
import { useDispatch } from "react-redux";
import { AppDispatch, getRecords } from "../store";
import { API_STATUS } from "../utils";
import CsvExportButton from "../components/notaryBook/CSVExportButton";
import { Layout } from "../components/Layout";
import { GrSearch } from "react-icons/gr";
import Dropdown from "../components/notaryBook/DropDown";
import { ActionMeta, SingleValue } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

const pagesCount: OptionType[] = [
  { value: "5", label: "5" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "75", label: "75" },
  { value: "100", label: "100" },
  { value: "All", label: "All" },
];

const NotaryBook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isRecordLoading, setIsRecordLoading] = useState(false);
  const [acknowledgments, setAcknowledgments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPageCount, setSelectedPageCount] = useState<OptionType>(pagesCount[4]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRecords, setFilteredRecords] = useState([]);

  const fetchRecords = async () => {
    try {
      setIsRecordLoading(true);
      const getRecordResponse = await dispatch(getRecords({ status: "finished" }));

      if (getRecordResponse.meta.requestStatus === API_STATUS.fulfilled) {
        const acknowledges = getRecordResponse.payload as [];
        setAcknowledgments([...acknowledges]);
        setFilteredRecords([...acknowledges]);
      }
      if (getRecordResponse.meta.requestStatus === API_STATUS.rejected) {
        console.error("Error fetching records");
      }
    } catch (error) {
      console.error("Error in get records", error);
    } finally {
      setIsRecordLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchRecords();
    })();
  }, []);

  useEffect(() => {
    const filtered = acknowledgments.filter((ack: any) =>
      ack.signerName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRecords(filtered);
    setCurrentPage(1);
  }, [searchTerm, acknowledgments]);

  const handleChangePageCount = useCallback(
    (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
      if (newValue !== null) {
        setSelectedPageCount(newValue);
        setCurrentPage(1);
      }
    },
    []
  );

  const getDisplayedRecords = () => {
    const startIndex = (currentPage - 1) * parseInt(selectedPageCount.value);
    const endIndex = selectedPageCount.value === "All" ? filteredRecords.length : startIndex + parseInt(selectedPageCount.value);
    return filteredRecords.slice(startIndex, endIndex);
  };

  const totalPages = selectedPageCount.value === "All" ? 1 : Math.ceil(filteredRecords.length / parseInt(selectedPageCount.value));

  return (
    <Layout>
      <Container>
        <Title>Electronic notary records</Title>
        <ActionRow>
          <SearchContainer>
            <SearchBox>
              <SearchInput
                placeholder="Search Records..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon />
            </SearchBox>
          </SearchContainer>

          <CsvButtonBox>
            <CsvExportButton records={acknowledgments} />
          </CsvButtonBox>
          <PageCountBox>
            <PageCountTitle>Records displayed per page</PageCountTitle>
            <Dropdown
              onChange={handleChangePageCount}
              value={selectedPageCount}
              options={pagesCount}
            />
          </PageCountBox>
        </ActionRow>
        {isRecordLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <AcknowledgmentContainer>
            {getDisplayedRecords().map((acknowledge: any) => (
              <AcknowledgmentBox key={acknowledge.id} acknowledge={acknowledge} />
            ))}
            {totalPages > 1 && (
              <Pagination>
                <PaginationButton
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </PaginationButton>
                <PaginationInfo>
                  Page {currentPage} of {totalPages}
                </PaginationInfo>
                <PaginationButton
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                >
                  Next
                </PaginationButton>
              </Pagination>
            )}
          </AcknowledgmentContainer>
        )}
      </Container>
    </Layout>
  );
};

export default NotaryBook;

const Container = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 40px 95px;
  padding-right: 40px;
  margin-bottom: 20px;
  position: relative;
`;

const ActionRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 93%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
`;

export const AcknowledgmentContainer = styled.div`
  margin-left: 6%;
  width: 70%;
`;

const Title = styled.h2`
  font-size: 1.953em;
  margin: 0;
  color: ${(props) => props.theme.colors.secondary};
`;

const Loader = styled.div`
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 6px solid #13160b;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SearchContainer = styled.div`
display: flex;
flex: 2;
`;

const SearchBox = styled.div`
  position: relative;
  width: 90%;
`;

const CsvButtonBox = styled.div`
  display: inline-grid;
  justify-content: center;
  flex: 2;
`;

const SearchInput = styled.input`
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SearchIcon = styled(GrSearch)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  width: 20px;
  height: 20px;
`;

const PageCountTitle = styled.div`
  color: #545f71;
  font-weight: 500;
  font-size: 13px;
`;

const PageCountBox = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 2;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  border-radius: 10px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const PaginationInfo = styled.span`
  font-size: 16px;
`;
