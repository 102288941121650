import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* Apply the Inter font family globally */
  body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
  }

  /* You can also define specific classes if needed */
  .inter-<uniquifier> {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
  }

  .inter-<uniquifier>-100 {
    font-weight: 100;
  }

  .inter-<uniquifier>-200 {
    font-weight: 200;
  }

  .inter-<uniquifier>-300 {
    font-weight: 300;
  }

  .inter-<uniquifier>-400 {
    font-weight: 400;
  }

  .inter-<uniquifier>-500 {
    font-weight: 500;
  }

  .inter-<uniquifier>-600 {
    font-weight: 600;
  }

  .inter-<uniquifier>-700 {
    font-weight: 700;
  }

  .inter-<uniquifier>-800 {
    font-weight: 800;
  }

  .inter-<uniquifier>-900 {
    font-weight: 900;
  }

  /* Example for italic */
  .inter-<uniquifier>-italic {
    font-style: italic;
  }
`;

export default GlobalStyle;
