import React, { forwardRef } from "react";
import styled from "styled-components";

interface FloatingLabelTextareaProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  readOnly: boolean;
  maxLength: number;
  onFocus?: () => void;
  onBlur?: () => void;
  rows?: number;
}

const FloatingLabelTextarea = forwardRef<HTMLTextAreaElement, FloatingLabelTextareaProps>(
  ({ label, value, onChange, readOnly, maxLength, onFocus, onBlur, rows = 1 }, ref) => (
    <MainBox>
      <TextInputBox>
        <TextareaWrapper>
          {label && <Label>{label}</Label>}
          <StyledTextarea
            ref={ref}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            maxLength={maxLength}
            rows={rows}
            placeholder="Click here to type"
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </TextareaWrapper>
      </TextInputBox>
      {/* <WordCount>{value.length} / {maxLength}</WordCount> */}
    </MainBox>
  )
);

export default FloatingLabelTextarea;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const TextInputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 4px 12px;
  font-size: 14.4px;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.red};
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  resize: none;
  outline: none;
  background-color: #fff;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const WordCount = styled.div`
  font-size: 12px;
  color: grey;
  text-align: right;
`;
