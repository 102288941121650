import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg";
import background from "../assets/signin-bg.svg";
import { resetPassword } from "aws-amplify/auth";

const ForgetPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      email: "",
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleForgetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    setMessage("");

    try {
      await resetPassword({
        username: formData.email,
      });
      setMessage("A password reset code has been sent to your email.");
      navigate("/reset-password", { state: { email: formData.email } });
    } catch (error: any) {
      setErrors({ ...errors, email: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <ForgetPasswordBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Forgot Password</Title>
        <Form onSubmit={handleForgetPassword} noValidate>
          <FieldBox>
            <Label>Email Address</Label>
            <Input
              type="email"
              name="email"
              placeholder="Enter here"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <ErrorText>{errors.email}</ErrorText>}
            {message && <SuccessText>{message}</SuccessText>}
            <ForgetPasswordCriteriaText>
              An email will be sent to you with instructions for resetting your
              password.
            </ForgetPasswordCriteriaText>
          </FieldBox>
          <ResetButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? <LoadingSpinner /> : "Get Password Reset Email"}
          </ResetButton>
        </Form>
      </ForgetPasswordBox>
    </PageContainer>
  );
};

export default ForgetPasswordPage;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  width: 100%;
`;

const ForgetPasswordBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
`;

const Form = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
`;

const FieldBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
`;

const ResetButton = styled.button`
  width: 70%;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Text = styled.p`
  margin-bottom: 10px;
  margin-top: 0px;
`;

const RegisterLink = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

const ForgetPasswordCriteriaText = styled.p`
  font-size: 11px;
  color: #666;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const SuccessText = styled.p`
  font-size: 12px;
  color: green;
  margin-top: 5px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
