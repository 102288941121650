import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg"; 
import background from "../assets/signin-bg.svg"; 
import { signIn } from 'aws-amplify/auth';
import { parseErrorMessage } from "../utils/helper";
import { AppDispatch, searchEmail } from "../store";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { saveToLocalStorage } from "../utils/localStorageUtil";

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isSignInLoading, setIsSignInLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); 
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {
      email: "",
      password: ""
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      valid = false;
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setIsSignInLoading(false);
      return;
    }
  
    setIsSignInLoading(true);
    
    try {
      const searchEmailResponse = await dispatch(
        searchEmail({email: formData.email})
      );
      if (searchEmailResponse.meta.requestStatus == "fulfilled") {
        const user = await signIn({ username: formData.email, password: formData.password });
        saveToLocalStorage("firmId", searchEmailResponse.payload?.result?.firmId);
        saveToLocalStorage("officialId", searchEmailResponse.payload?.result?.officialId);
        navigate("/");
      }
      console.log("searchEmailResponse", searchEmailResponse)
      if (searchEmailResponse.meta.requestStatus == "rejected") {
        throw new Error("You are not onboarded");
      }
    } catch (error: any) {
      console.log("error in sign in", error);
      setErrors({ ...errors, email: parseErrorMessage(error.message) });
    } finally {
      setIsSignInLoading(false);
    }
  };

  return (
    <PageContainer>
      <SignInBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Sign in</Title>
        <Form onSubmit={handleSignIn} noValidate>
          <FieldBox>
            <Label>Email Address</Label>
            <Input
              type="email"
              name="email"
              placeholder="Enter here"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            
          </FieldBox>
          <FieldBox>
            <Label>Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="Enter here"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
           {errors.email && <ErrorText>{errors.email}</ErrorText>}
          </FieldBox>
          <SignInButton type="submit">{ isSignInLoading ? <LoadingSpinner/> :"Sign In"}</SignInButton>
        </Form>
        <TextContainer>
          <Text>
            Don't have an account? <RegisterLink href="/register">Register now</RegisterLink>
          </Text>
          <ForgotPasswordLink href="/forget-password">Forgot password?</ForgotPasswordLink>
        </TextContainer>
      </SignInBox>
    </PageContainer>
  );
};

export default SignInPage;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  width: 100%;
`;

const SignInBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
`;

const Form = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
`;

const SignInButton = styled.button`
  width: 70%;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Text = styled.p`
  margin-bottom: 10px;
`;

const RegisterLink = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ForgotPasswordLink = styled.a`
  text-decoration: none;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.secondary};
  
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
