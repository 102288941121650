import React, { useEffect, useState, useRef, useCallback } from "react";
import ScheduleBox from "./ScheduleBox";
import { GrSearch } from "react-icons/gr";
import { FaFilter, FaSpinner } from "react-icons/fa";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppDispatch, getSessions, getSessionsByAutoSearch, markSessionAsViewed, sessionBatch } from "../../store";
import debounce from "lodash.debounce";
import DateInputWithIcon from "./DateInput";
import { ReactComponent as DownIcon } from "../../assets/down-icon.svg";
import { ReactComponent as DropdownIcon } from "../../assets/chevron-down.svg";
import { toCamelCase } from "../../utils/helper";

interface Session {
  id: string;
  title: string;
  date: string;
  name: string;
  appointmentId: string;
}

interface Sessions {
  scheduled: Session[];
  completed: Session[];
}

const SessionTimeline: React.FC<{
  onSessionSelect: (session: any) => void;
  activeSessionId?: string;
  onTabChange: (activeTab: "scheduled" | "completed") => void;
}> = ({ onSessionSelect, activeSessionId, onTabChange }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isMounted = useRef(true);
  const [selectedOption, setSelectedOption] = useState<string>("name");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const activeSessionIdRef = useRef(activeSessionId);
  const suggestionBoxRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  const [activeTab, setActiveTab] = useState<"scheduled" | "completed">(
    "scheduled"
  );
  const handleTabChange = (tab: "scheduled" | "completed") => {
    setSearchTerm("");
    setShowFilters(false);
    setShowSuggestions(false)
    setActiveTab(tab);
    onTabChange(tab);
  };

  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [sessions, setSessions] = useState<Sessions>({
    scheduled: [],
    completed: [],
  });
  const [searchSessions, setSearchSessions] = useState<Sessions>({
    scheduled: [],
    completed: [],
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilterDropdown = () => setShowFilters((prev) => !prev);
  const sessionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideDropdown = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };



  const handleBoxClick = async (item: any) => {
    setShowSuggestions(false);
    const sessionMarkedAsViewed = dispatch(
      markSessionAsViewed({
        sessionId: item?.id
      })
    );


    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    const activeSession = sessions[activeTab].find(
      (session: any) => session.id.toString() === item.id
    );
    if (activeSession) {
      sessionRefs.current[item.id]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      onSessionSelect(activeSession);
      setSelectedBox(sessions[activeTab].indexOf(activeSession));
    }
    // if (searchSessions[activeTab].length > 0) {
    //   console.log("selected item", item)
    //   let index = sessions[activeTab].indexOf(item);
    //   setSelectedBox(index);
    //   onSessionSelect(item);
    // }
  };



  const handleDropdownToggle = () => setShowDropdown((prev) => !prev);
  const handleOptionSelect = (option: string) => {
    console.log("option", option);
    setSelectedOption(option);
    setShowDropdown(false);
  };

  const fetchSessions = useCallback(
    debounce(async (search: string, tab: "scheduled" | "completed", selectedOption: string) => {
      try {
        if (!search) {
          setSearchSessions({
            scheduled: [],
            completed: [],
          })
          setIsSessionLoading(true);
        }

        const fetchBothSessions = async () => {
          const [scheduledResponse, completedResponse] = await Promise.all([
            dispatch(getSessions({ status: "scheduled", search: "" })),
            dispatch(getSessions({ status: "completed", search: "" })),
          ]);
          let foundSessions = {
            ...sessions,
          }
          if (scheduledResponse.meta.requestStatus === "fulfilled") {
            foundSessions = {
              ...foundSessions,
              scheduled: scheduledResponse.payload
            }
            setSessions((prev: any) => ({
              ...prev,
              scheduled: scheduledResponse.payload,
            }));
          }
          if (completedResponse.meta.requestStatus === "fulfilled") {
            foundSessions = {
              ...foundSessions,
              completed: completedResponse.payload,
            }
            setSessions((prev: any) => ({
              ...prev,
              completed: completedResponse.payload,
            }));
          }
          let foundSession = foundSessions[activeTab].find(
            (updated: any) => activeSessionIdRef.current == updated.id
          );
          if (foundSession) {
            console.log("foundSession in fetch sessions", foundSession)
            onSessionSelect(foundSession);
            setSelectedBox(foundSessions[activeTab].indexOf(foundSession));
          }
        };

        if (search) {
          const response = await dispatch(getSessionsByAutoSearch({ type: selectedOption == "Appt ID" ? "appointmentId" : selectedOption == "notary" ? "official" : selectedOption, search, status: tab == "scheduled" ? "active" : "completed" }));
          if (response.meta.requestStatus === "fulfilled") {
            setSearchSessions((prev: any) => ({
              ...prev,
              [tab]: response.payload,
            }));
          }
        } else {
          await fetchBothSessions();
        }
      } catch (error) {
        console.error("Error in get session API", error);
      } finally {
        setIsSessionLoading(false);
        setIsSearching(false);
      }
    }, 300),
    [dispatch]
  );


  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionBoxRef.current &&
      !suggestionBoxRef.current.contains(event.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [showDropdown]);


  useEffect(() => {
    activeSessionIdRef.current = activeSessionId;
    console.log("activeSessionId", activeSessionId)
    if(!activeSessionId && sessions[activeTab].length > 0){
      onSessionSelect(sessions[activeTab][0]);
      setSelectedBox(0);
    }
  }, [activeSessionId, sessions]);

  useEffect(() => {
    isMounted.current = true;
    fetchSessions(searchTerm, activeTab, selectedOption);
    return () => {
      isMounted.current = false;
      fetchSessions.cancel();
    };
  }, [searchTerm, fetchSessions]);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      fetchSessions(searchTerm, activeTab, selectedOption);
    }
    if (sessions[activeTab].length > 0) {
      onSessionSelect(sessions[activeTab][0]);
    }
  }, [activeTab, searchTerm, fetchSessions, selectedOption]);

  useEffect(() => {
    if (!searchTerm.trim()) {
      fetchSessions("", "scheduled", selectedOption);
      fetchSessions("", "completed", selectedOption);
    }
  }, [fetchSessions]);

  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    if (!searchTerm.trim()) {
      console.log("searchTerm.trim()", searchTerm.trim())
      intervalId.current = setInterval(async () => {
        const sessionIds =
          activeTab === "scheduled"
            ? sessions.scheduled.map((item: any) => item.id.toString())
            : sessions.completed.map((item: any) => item.id.toString());
        console.log('sessionIds.length', sessionIds.length)
        if (sessionIds.length > 0) {
          const response = await dispatch(sessionBatch({ sessionIds }));
          if (response.meta.requestStatus === "fulfilled") {
            const updatedSessions = response.payload;
            let foundSession: any = updatedSessions.find(
              (updated: any) => activeSessionIdRef.current == updated.id
            );
            const updatedTabSessions = sessions[activeTab].map((session: any) => {
              if (session?.id == activeSessionIdRef.current) {
                return { ...session, ...foundSession };
              } else {
                return session;
              }
            });
            let tempSessions = { ...sessions, [activeTab]: [...updatedTabSessions] }
            if (sessions[activeTab].length > 0) {
              foundSession = [...updatedTabSessions].find(
                (updated: any) => activeSessionIdRef.current == updated.id
              );
              setSessions(tempSessions);
            }
            if (foundSession) {
              console.log("foundSession", foundSession)
              onSessionSelect(foundSession);
              setSelectedBox(tempSessions[activeTab].indexOf(foundSession));
            }
          }
        }
      }, 5000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [dispatch, activeTab, sessions, activeSessionId, searchTerm]);

  const handleStartDateChange = (date: Date | null, event?: React.SyntheticEvent) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date: Date | null, event?: React.SyntheticEvent) => {
    setEndDate(date);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim()) {
      setShowDropdown(false)
      setShowSuggestions(true);
      setIsSearching(true);
    } else {
      setShowSuggestions(false);
      setIsSearching(false);
    }
  };

  return (
    <SessionInfoBox>
      <Tabs>
        <Tab
          isActive={activeTab === "scheduled"}
          onClick={() => handleTabChange("scheduled")}
        >
          Scheduled
        </Tab>
        <Tab
          isActive={activeTab === "completed"}
          onClick={() => handleTabChange("completed")}
        >
          Completed
        </Tab>
      </Tabs>
      <SearchBox>
        <SearchInputAndDropdown>
          <DropdownContainer ref={dropdownRef}>
            <DropdownButton onClick={handleDropdownToggle}>
              {selectedOption == "name" ? "Principal" : toCamelCase(selectedOption) || "Search By"}
              <DownIcon />
            </DropdownButton>
            {showDropdown && (
              <DropdownMenu showDropdown={showDropdown}>
                <DropdownItem onClick={() => handleOptionSelect("name")}>
                  Principal
                </DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("notary")}>
                  Notary
                </DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("Appt ID")}>
                  Appointment ID
                </DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("email")}>
                  Email
                </DropdownItem>
              </DropdownMenu>
            )}
          </DropdownContainer>
          <SearchInput
            placeholder=""
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          {isSearching ? <LoadingIcon /> : <SearchIcon />}
        </SearchInputAndDropdown>

        {showSuggestions && searchTerm && (
          <SuggestionBoxContainer ref={suggestionBoxRef}>
            <SuggestionsBox>
              {searchSessions[activeTab].map((session: Session) => (
                <SuggestionItem
                  key={session.id}
                  onClick={() => handleBoxClick(session)}
                >
                  {session.name} ({session.appointmentId})
                </SuggestionItem>
              ))}
              {searchSessions[activeTab].length === 0 && (
                <NoSuggestions>No results found</NoSuggestions>
              )}
            </SuggestionsBox>
          </SuggestionBoxContainer>
        )}
      </SearchBox>
      <ScheduleBoxContainer isLoading={isSessionLoading}>
        {isSessionLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : sessions[activeTab].length === 0 ? (
          <NoItemsFound>No items found</NoItemsFound>
        ) : (
          sessions[activeTab]?.map((item: any, index: number) => (
            <div
              key={item.id}
              ref={(el) => (sessionRefs.current[item.id] = el)}
            >
              <ScheduleBox
                key={index}
                isSelected={selectedBox === index}
                onClick={() => handleBoxClick(item)}
                item={item}
              />
            </div>
          ))
        )}
      </ScheduleBoxContainer>
    </SessionInfoBox>
  );
};

export default SessionTimeline;

const NoItemsFound = styled.div`
  text-align: center;
  color: #888;
   margin-top: 18px;
  font-size: 16.2px; 
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
   width: 45%; 
`;

const ScheduleBoxContainer = styled.div<{ isLoading: boolean }>`
  padding: 8px;
  display: ${(props) => (props.isLoading ? "flex" : "block")};
  justify-content: center;
  overflow-y: auto;
  flex: 1;
  cursor: pointer;
  padding-top: 0px; 
  padding-bottom: 18px; 
    :: -webkit-scrollbar {
    display: none;
  }
`
  ;

// const CreateButton = styled(Button)
//   gap: 16px;
//   font-size: 18px;
// ;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

const AdjustmentIconBox = styled.div<{ showFilters: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) =>
    props.showFilters ? props.theme.colors.primary : "white"
  };
  padding: 10px;
  border: ${(props) =>
    !props.showFilters && "1px solid #ccc"
  };

  border-radius: 4px;
  cursor: pointer;
;`

const SessionTitle = styled.h2`
text-align: left;
margin-bottom: 25px;
;
`
const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 9px 18px;
  justify-content: space-between;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid
      ${(props) =>
    props.isActive ? props.theme.colors.secondary : "transparent"
  };
  color: ${(props) =>
    props.isActive ? props.theme.colors.secondary : "black"
  };
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  `;

const FilterIcon = styled(FaFilter)`
  // position: absolute;
  // right: 45px; 
  // top: 50%;
  // transform: translateY(-50%);
  // cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
`;

const FilterOption = styled.div`
  margin-bottom: 16px;
    label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }`;

const FilterLabel = styled.div`
  font-size: 15px;
  margin-bottom: 14px;
  color: #545F71;
`;
const OfficialHeading = styled.div`
  font-size: 15px;
  margin-bottom: 2px;
  color: #545F71;
`
  ;

const FilterButtonBox = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
gap: 8px;
`;

const FilterDropdown = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-top: 10px;
  z-index: 1000;
  width: calc(100% - 50px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const OfficialHeadingAndIcon = styled.div`
display: flex;
gap: 8px;
justify-content: space-between;
border-bottom: 1px solid #ccc;
padding-bottom: 10px;
margin-bottom: 10px;
`;

const FilterBoxContainer = styled.div`
padding: 0px 8px;
width: 90%;
`;

const Button = styled.button<{ variant?: string }>`
padding: 10px 16px;
border-radius: 6px;
border: none;
flex: 1;
cursor: pointer;

 ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: #eef1f4;
    color: #545F71;
  `}
`;

const SessionInfoBox = styled.div`
  width: 98%;
  background-color: #f9f9f9;
  padding: 18px 9px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SearchBox = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchInputAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 8px;
  margin-top: 18px;
  position: relative;
`;

const SearchInput = styled.input`
  width: 60%;
  padding: 14.4px 48px 14.4px 14.4px;
  font-size: 16.2px;
  border: 1px solid #D9D9D9;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-left: none;
  outline: none;
`;

// const SearchIcon = styled(GrSearch)`
//   position: absolute;
//   right: 16px;
//   top: 50%;
//   transform: translateY(-50%);
//   color: ${(props) => props.theme.colors.primary};
//   width: 24px; 
//   height: 24px;
//   pointer-events: none;
// `;

const SuggestionsBox = styled.div`
  position: absolute;
  width: 95%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 7px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  top: calc(100% + 4px);
`;

const SuggestionBoxContainer = styled.div`
  padding: 0px 8px;
`;

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const NoSuggestions = styled.div`
  padding: 10px;
  color: #888;
`;


const DropdownItem = styled.div`
  padding: 10px;
  color: #545F71;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid #ccc; 
  }
`;

const SearchInputAndDropdown = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 18px;
  position: relative;
  width: 96%; 
`;

const DropdownContainer = styled.div`
  width: 40%; 
`;


const DropdownButton = styled.button`
  width: 100%; 
  padding: 15px 6px;
  font-size: 16.2px;
  border: 1px solid #D9D9D9; 
  border-left: 1px solid #D9D9D9; 
  border-right: 1px solid #D9D9D9; 
  border-top-left-radius: 7px; 
  border-bottom-left-radius: 7px;
  border-top-right-radius: 0; 
  border-bottom-right-radius: 0; 
  background-color: #F0F0F0;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #5E5D5D;
`;


const DropdownMenu = styled.div<{ showDropdown: boolean }>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%; 
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${(props) => (props.showDropdown ? "block" : "none")};
`;

const LoadingIcon = styled(FaSpinner)`
  position: absolute;
  right: 16px;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SearchIcon = styled(GrSearch)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
`;

