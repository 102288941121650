import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import styled from "styled-components";
import Home from "./pages/home";
import NotaryBook from "./pages/notaryBook";
import CreateSession from "./pages/createSession";
import ForgetPassword from "./pages/forget-password";
import ResetPassword from "./pages/reset-password";
import Login from "./pages/login";
import Register from "./pages/register";
import RegisterConfirmation from "./pages/register-confirmation";
import ConfirmSignUp from "./pages/confirm-signup";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast-styles.css";
import ProtectedLayout from "./components/ProtectedLayout";
import "react-datepicker/dist/react-datepicker.css";
import SessionRequest from "./pages/sessionRequest";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-export";
import GlobalStyle from "./GlobalStyle";

Amplify.configure(awsconfig);

declare global {
  interface Window {
    Persona: any;
  }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppBox>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedLayout>
                <Home />
              </ProtectedLayout>
            }
          />
          <Route
            path="/createSession"
            element={
              <ProtectedLayout>
                <CreateSession />
              </ProtectedLayout>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-confirmation" element={<ConfirmSignUp />} />
          <Route path="/confirm-signup" element={<RegisterConfirmation />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/sessionRequests"
            element={
              <ProtectedLayout>
                <SessionRequest />
              </ProtectedLayout>
            }
          />
          <Route
            path="/notary/:notaryId"
            element={
              <ProtectedLayout>
                <SessionRequest />
              </ProtectedLayout>
            }
          />
          <Route
            path="/notaryBook"
            element={
              <ProtectedLayout>
                <NotaryBook />
              </ProtectedLayout>
            }
          />
          <Route
            path="/session/:sessionId"
            element={
              <ProtectedLayout>
                <Home />
              </ProtectedLayout>
            }
          />
        </Routes>
      </AppBox>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="toast-container"
      />
    </ThemeProvider>
  );
}

export default App;

const AppBox = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  position: relative;
  display: flex;
  width: 92%;
  left: 8%;
`;
