import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";

interface FirebaseOptions {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBRTfwLm_yVQ68KurnDx9k-vwisiTxW5VQ",
  authDomain: "development-onfile.firebaseapp.com",
  databaseURL: "https://development-onfile.firebaseio.com",
  projectId: "development-onfile",
  storageBucket: "development-onfile.appspot.com",
  messagingSenderId: "985328307866",
  appId: "1:985328307866:web:c097d51b99a7d511a39923",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { database, ref, get, auth };
