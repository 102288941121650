// src/services/permissions.ts
import { database, get, ref } from "./firebaseConfig";
import { getFromLocalStorage } from "./localStorageUtil";

interface PermissionCheckProps {
  firmId: string;
  userId: string;
}

interface FeatureParams {
  userId: string;
}

export const isFeatureAvailable = (
  featureValue: any,
  params: FeatureParams
): boolean => {
  const { userId } = params;

  if (featureValue === undefined) {
    return false;
  }

  if (typeof featureValue === "boolean") {
    return featureValue;
  }

  if (typeof featureValue === "object") {
    const keys = Object.keys(featureValue);

    for (let i = 0; i < keys.length; i++) {
      if (featureValue[keys[i]] === userId) {
        return true;
      }
    }
  }

  return false;
};

const getPermissions = async (firmId: string) => {
  const path = `/whitelist/${firmId}/permissions`;
  const permissionRef = ref(database, path);

  const snapshot = await get(permissionRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    console.log("No data available at this path.");
    return null;
  }
};

export const isFeatureAvailableInFirebase = async (
  feature: string,
  { firmId, userId }: PermissionCheckProps
): Promise<boolean> => {
  const permissions = await getPermissions(firmId);
  if (permissions) {
    return isFeatureAvailable(permissions[feature], { userId });
  }
  return false;
};

export const checkFeatureAvailability = async () => {
  let firmId = getFromLocalStorage("firmId");
  let officialId = getFromLocalStorage("officialId");
  const feature = "notaryScheduling";
  const userId = officialId;

  const isAvailable = await isFeatureAvailableInFirebase(feature, {
    firmId,
    userId,
  });
  return isAvailable;
};
