import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { FaRegIdCard } from "react-icons/fa";
import { isUserOnline, STAGES_BEFORE_CAMERA_CHECK } from "../../utils/sessionHelper";
import { ReactComponent as NetworkErrorIcon } from "../../assets/networkError.svg";
import { ReactComponent as VideoFilledIcon } from "../../assets/video-filled.svg";
import { ReactComponent as VideoEmptyIcon } from "../../assets/nowebrtc.svg";
import { ReactComponent as WebRtcErrorIcon } from "../../assets/webrtc-error.svg";

interface ScheduleBoxProps {
  isSelected: boolean;
  item: any;
  onClick: () => void;
}

const ScheduleBox: React.FC<ScheduleBoxProps> = ({
  isSelected,
  onClick,
  item,
}) => {
  const [networkError, setNetworkError] = useState(false);
  const [isSignerWebRTCOk, setIsSignerWebRTCOk] = useState(false);

  const initialDate = useMemo(() => {
    return item?.scheduledAt?._seconds
      ? moment(item.scheduledAt._seconds * 1000).toDate()
      : new Date();
  }, [item?.scheduledAt]);

  // const isWithinFourHours = useMemo(() => {
  //   const currTimestamp = +new Date();
  //   return item?.scheduledAt?._seconds * 1000 - currTimestamp < 0;
  // }, [item?.scheduledAt]);

  const scheduleStatus = useMemo(() => {
    const currTimestamp = +new Date();
    const scheduledTimestamp = item?.scheduledAt?._seconds * 1000;

    if (scheduledTimestamp <= currTimestamp) {
      return "started";
    } else if (scheduledTimestamp - currTimestamp <= 60 * 60 * 1000) {
      return "soon";
    } else {
      return "future";
    }
  }, [item?.scheduledAt]);

  const isOfficialOnline = useMemo(() => isUserOnline(item), [item]);
  const isSignerOnline = useMemo(() => isUserOnline(item, 'signerLastOnlineAt'), [item]);
  
  useEffect(() => {
    if (!item.isCompleted) {
      let isSignerOnlineTemp = isUserOnline(item, 'signerLastOnlineAt');
      // setIsSignerOnline(isSignerOnlineTemp);
      if (
        !item.userIO ||
        !item.userIO.audioDevices ||
        !item.userIO.videoDevices ||
        !(item.userIO.audioDevices.length && item.userIO.videoDevices.length)
      ) {
        if (
          !STAGES_BEFORE_CAMERA_CHECK.includes(item.stage) &&
          isSignerOnlineTemp
        ) {
          setNetworkError(true);
        } else {
          setNetworkError(false);
        }
      } else {
        setNetworkError(false);
        setIsSignerWebRTCOk(true);
      }
    }
  }, [item.isCompleted, item.userIO, item.stage]);

  return (
    <Container isselected={isSelected} onClick={onClick}>
      <Header>
        <LeftText>
          <Name>
            {item?.name}
          </Name>

          <IconsBox>
            {item?.idCaptureError && <FaRegIdCard width={"17px"} height={"17px"} />}
            {(networkError || item?.networkError) && <NetworkErrorIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && (item?.userVideoJoined)) && <VideoFilledIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && !(item?.userVideoJoined) && !item?.isSignerWebRTCError) && <VideoEmptyIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && item?.isSignerWebRTCError) && <WebRtcErrorIcon width={"17px"} height={"17px"} />}
          </IconsBox>


        </LeftText>
        {
          item?.isViewed && (<RightText>Viewed</RightText>)
        }
      </Header>
      <NotaryOwner>
        <ScheduleText status={scheduleStatus}>
          {moment(initialDate).format("M/D/YY h:mm a")}
        </ScheduleText>{" "}
        {!isOfficialOnline && isSignerOnline && <StatusDot color="red" />}
        {isOfficialOnline && <StatusDot color="green" />}
      </NotaryOwner>
      <InfoRow>
        <InfoBlock>
          <Value>{item?.appointmentId}</Value>
        </InfoBlock>
        <InfoBlock>
          <Value>{item?.official?.name}</Value>
        </InfoBlock>
      </InfoRow>
    </Container>
  );
};

export default ScheduleBox;


export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F7F7F7;
  margin-top: 7.2px;
  padding: 12.6px;
  border-radius: 8px;
`;

export const IconsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 3px;
`;

export const StatusDot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color == "green" ? "#41B050": "#ea4125"};
  border-radius: 50%;
`;

export const Name = styled.span`
  display: block;
  font-size: 17px;
  font-weight: 600;
  word-wrap: break-word; /* Allows long words to break */
  white-space: normal; /* Allow the text to wrap */
  overflow: hidden;
  
  /* Limit the width to approximately 20 characters */
  max-width: 18ch;

  /* Ensure the text wraps to the next line */
  line-height: 1.5; /* Adjust line height if needed */
`;

export const Container = styled.div<{
  isselected: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.isselected ? "1.5px solid blue" : "none")};
  overflow-y: auto;
  cursor: pointer;
  background: ${(props) => (props.isselected ? props.theme.colors.light : "white")};


  /* Adding box-shadow when selected */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* Hover effect */
  &:hover {
    background: ${(props) => (props.isselected ? props.theme.colors.light : "#F0F0F0")};

    ${InfoRow} {
      background-color: ${(props) => (!props.isselected && "white")} ;
    }
  }
  padding: 14.4px; /* Reduced from 16px */
  max-height: 360px; /* Reduced from 400px */
  margin-bottom: 18px; /* Reduced from 20px */
  border-radius: 5.4px; 
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
`;

export const LeftText = styled.div`
  color: #333344;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
`;


export const RightText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.primary};
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.span`
  color: #555;
 font-size: 12.6px;
`;

export const NotaryOwner = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 14.4px;
  margin-top: 2px;
  margin-bottom: 0px;
`;

export const Value = styled.span`
  font-size: 14.4px; 
  font-weight: 500; 
`;

export const ScheduleText = styled.span<{ status: string }>`
 font-size: 14.4px;
  color: ${(props) =>
    props.status === "started"
      ? "#EA4125"
      : props.status === "soon"
        ? "#E0B33D"
        : "#000"};
  font-weight: 550;
`;
