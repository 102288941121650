import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

interface CounterProps {
  value: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
  styles?: any; // Optional minHeight prop
}

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid black;
  border-radius: 6px;
  background-color: white;
  color: black;
  padding: 5px;
  width: 25%;
  // margin-top: -6px;
  height: 23px;
  box-shadow: none;
  &:hover {
    border-color: black;
  }
`;

const Input = styled.input`
  text-align: center;
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding-right: 32px; /* Add padding to accommodate icons */
  text-align: left;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Counter: React.FC<CounterProps> = ({
  value,
  onChange,
  min = 0,
  max = Infinity,
  styles,
}) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  const handleIncrement = () => {
    if (value < max) {
      const newValue = value + 1;
      setInputValue(newValue.toString());
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      const newValue = value - 1;
      setInputValue(newValue.toString());
      onChange(newValue);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
      setInputValue(newValue);
    }
  };

  const handleInputBlur = () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onChange(newValue);
    } else {
      setInputValue(value.toString());
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const newValue = parseInt(inputValue, 10);
      if (!isNaN(newValue) && newValue >= min && newValue <= max) {
        onChange(newValue);
      } else {
        setInputValue(value.toString());
      }
    }
  };

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  return (
    <CounterContainer style={styles}>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyPress={handleInputKeyPress}
      />
      <IconContainer>
        <MdExpandLess onClick={handleIncrement} style={{ cursor: "pointer" }} />
        <MdExpandMore onClick={handleDecrement} style={{ cursor: "pointer" }} />
      </IconContainer>
    </CounterContainer>
  );
};

export default Counter;
