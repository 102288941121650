import React, { useState } from "react";
import styled from "styled-components";
import { confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/Logo-Text.svg";
import background from "../assets/signin-bg.svg";
import { parseErrorMessage } from "../utils/helper";

const ConfirmSignUp: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const email = location.state?.email || "";
  const [resendMessage, setResendMessage] = useState("");
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleConfirm = async () => {
    setError("");
    setIsConfirmLoading(true);
    if (!code) {
      return;
    }

    try {
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      navigate("/login");
      setIsConfirmLoading(false);
    } catch (error: any) {
      setIsConfirmLoading(false);
      setResendMessage("");
      setError(parseErrorMessage(error.message));
    }
  };

  const handleResendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await resendSignUpCode({
        username: email,
      });
      setResendMessage("A new confirmation code has been sent to your email.");
      setError("");
    } catch (error: any) {
      setResendMessage("");
      setError(parseErrorMessage(error.message));
    }
  };

  return (
    <PageContainer>
      <ConfirmBox>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Confirm Sign Up</Title>
        <Description>
          A confirmation code was sent to your email: {email}. Please enter it
          below to complete your registration.
        </Description>
        <Form onSubmit={handleConfirm} noValidate>
          <FieldBox>
            <Label>Confirmation Code</Label>
            <Input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter your confirmation code"
              required
            />
            {error && <ErrorText>{error}</ErrorText>}
            {resendMessage && <SuccessText>{resendMessage}</SuccessText>}
          </FieldBox>
          <ConfirmButton type="button" onClick={handleConfirm}>
            {isConfirmLoading ? <LoadingSpinner /> : "Confirm"}
          </ConfirmButton>
          <ResendLink onClick={handleResendCode}>
            Resend Confirmation Code
          </ResendLink>
        </Form>
      </ConfirmBox>
    </PageContainer>
  );
};

export default ConfirmSignUp;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  width: 100%;
`;

const ConfirmBox = styled.div`
  width: 50%;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const FieldBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box; /* Ensures padding is included in width */
`;

const ConfirmButton = styled.button`
  width: 50%;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
`;

const ResendLink = styled.button`
  margin-top: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;

const SuccessText = styled.p`
  font-size: 12px;
  color: green;
  margin-top: 5px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
